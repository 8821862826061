{
  "devise.password_change.form.password_invalid": "Salasana ei täytä vaatimuksia",
  "devise.password_change.form.password_match": "Salasanat täsmäävät",
  "devise.password_change.form.password_mismatch": "Salasanat eivät täsmää",
  "devise.password_change.form.password_valid": "Salasana täyttää vaatimukset",
  "document_upload.file_error_title": "Asiakirjaa ei voi ladata",
  "document_upload.file_type_error": "Tarkista, että asiakirjan tiedostomuoto on yhteensopiva.",
  "document_upload.upload_error": "Latausprosessin aikana tapahtui verkkovirhe. Yritä ladata asiakirja uudelleen.",
  "document_upload.upload_error_title": "On tapahtunut virhe",
  "patient_forms.errors.invalid": "Yksi tai useampi lomakekenttä puuttuu tai sen muoto on virheellinen. Korjaa punaisella merkityt kentät. Voit myös tallentaa lomakkeen luonnoksena, mutta sinun on lähetettävä se myöhemmin, jotta käytäntösi voi käyttää sitä.",
  "patient_forms.errors.loading": "Olemassa olevien kenttien arvojen lataamisessa tapahtui virhe. Voit aloittaa alusta tai ottaa yhteyttä tukeen.",
  "patient_forms.errors.submitting": "Tallentaminen epäonnistui. Tarkista internet-yhteytesi ja yritä myöhemmin uudelleen."
}
