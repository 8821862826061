import { AESEncryptionKey } from '../encryption/encryption'

import getFileStream from './downloadFiles'

export default async function fetchDecryptedFileFromStream(
  fileId: string,
  encryptionKey: AESEncryptionKey,
  encoding: string = 'utf-8',
): Promise<string> {
  const fileStream = await getFileStream(fileId, encryptionKey)
  const decodedFileStream = fileStream.pipeThrough(new TextDecoderStream(encoding))
  const reader = decodedFileStream.getReader()

  let fileContent = ''
  while (true) {
    const { value: chunk, done } = await reader.read()

    if (done) break

    fileContent += chunk
  }

  return fileContent
}
