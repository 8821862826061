import { createElement } from './createElement'

export const jsx = createElement
export const jsxs = createElement

// These types are documented in
// https://www.typescriptlang.org/docs/handbook/jsx.htm
export namespace JSX {
  /* eslint-disable no-use-before-define, no-unused-vars */
  // The type of JSX elements. I.e. strings and component-functions.
  export type ElementType =
    // All the valid lowercase tags
    | keyof IntrinsicElements
    // Function components
    | ((props: any) => Element)
  // // Class components - currently not supported by our renderer
  // | (new (props: any) => ElementClass)

  // The return type of the createElement function, and any component function.
  export type Element = HTMLElement | null
  export interface ElementClass {}
  export interface ElementAttributesProperty {}
  export interface ElementChildrenAttribute {
    children: {}
  }

  type ElementNode = Element | Element[] | string | number | boolean | null
  export type Children = ElementNode | ElementNode[]

  // Additional attributes that are allowed for _all_ jsx-statements, in
  // addition to the specific IntrinsicClassAttributes and IntrinsicElements.
  export interface IntrinsicAttributes {
    key?: string | number
  }

  // Changes to the default HTML attributes that come with the Element
  interface Overrides {
    children?: Children | undefined
  }
  type WithJSXOverrides<T> = Omit<T, keyof Overrides> & Overrides

  // Specific attributes for class components.
  export interface IntrinsicClassAttributes<_TClassComponent> {}
  export type IntrinsicElements = {
    [ElementType in keyof HTMLElementTagNameMap]: WithJSXOverrides<
      Partial<HTMLElementTagNameMap[ElementType]>
    >
  }
}
