import { Controller } from '@hotwired/stimulus'

import { t } from 'translations/i18n'

import { loadDeviceKeyFromSessionOnce } from '../../encryption/encryption'

import { getDecryptedKey } from '../../services/downloadFiles'
import fetchDecryptedFileFromStream from '../../services/fetchDecryptedFileFromStream'

/**
 * MedicationPlanLoader fetches the medication plan and prepares it for rendering
 */
// Connects to data-controller="medication-plan-loader"
export default class extends Controller<HTMLDivElement> {
  static values = {
    fileId: String,
    encryptionKeyData: String,
    encryptionKeyKeyPairId: String,
  }

  async connect() {
    try {
      await loadDeviceKeyFromSessionOnce()

      const encryptionKey = await getDecryptedKey({
        patientKeyPairId: this.encryptionKeyKeyPairIdValue,
        encryptedData: this.encryptionKeyDataValue,
      })

      // medication plan files are encoded in iso-8859-1
      const medicationPlanXml = await fetchDecryptedFileFromStream(
        this.fileIdValue,
        encryptionKey,
        'iso-8859-1',
      )

      this.bmpLoaded(medicationPlanXml)
    } catch (error) {
      this.showError(t('medication_plan.loader_error'), error)
    }
  }

  bmpLoaded(bmp: string) {
    this.dispatch('bmpLoaded', { detail: bmp })
  }

  showError(message: string | string[], error?: unknown) {
    error && console.error(error)
    alert(message) // eslint-disable-line no-alert
  }

  declare fileIdValue: string
  declare encryptionKeyDataValue: string
  declare encryptionKeyKeyPairIdValue: string
}
