import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="menu"
export default class extends Controller {
  encryptionMenuItemTarget!: Element
  encryptionBadgeTarget!: Element
  encryptionUnlocked: Boolean = false
  hasEncryptionMenuItemTarget!: Boolean
  hasEncryptionBadgeTarget!: Boolean

  static targets = ['encryptionMenuItem', 'encryptionBadge']

  async connect() {
    const sessionKeyLoaded = Boolean(window.sessionStorage.getItem('deviceKey'))

    if (sessionKeyLoaded) {
      this.unlock()
    }
  }

  unlock() {
    this.encryptionUnlocked = true
    if (this.hasEncryptionMenuItemTarget) {
      this.encryptionMenuItemTarget.classList.add('hidden')
    }
    if (this.hasEncryptionBadgeTarget) {
      this.encryptionBadgeTarget.classList.remove('hidden')
    }
  }
}
