import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="flash"
export default class extends Controller {
  declare timeoutId: number

  remove() {
    this.element.remove()
  }

  disconnect() {
    window.clearTimeout(this.timeoutId)
  }

  connect() {
    this.timeoutId = window.setTimeout(() => {
      this.remove()
    }, 10_000)
  }
}
