import { BMPElement, SchemaValidation } from './types'

/**
 * Finds element by its @name child property
 */
export function findElementInSchemaByName(name: string, jsonObj: {}): BMPElement | null {
  let element = null

  traverse(jsonObj, (key, value, parent) => {
    if (key === '@name' && value === name) {
      element = parent
      return false
    }
    // keep searching object
    return true
  })

  return element
}

/**
 * Traverse an object, calling filter on each key/value
 * pair to know whether to continue
 */
function traverse(obj: {}, filter: (key: string | number, value: string, parent: {}) => boolean) {
  if (typeof obj !== 'object' || obj === null) return

  Object.entries(obj).forEach(([key, value]) => {
    // Key is either an array index or object key
    if (filter(key, value, obj)) traverse(value, filter)
  })
}

/**
 * Adds an error message to the validation object.
 * Always sets valid to false.
 */
export function addError(errorMessage: string, validation: SchemaValidation) {
  validation.valid = false
  validation.errors.push(errorMessage)
}

/**
 * Adds a warning message to the validation object.
 * Always sets valid to false.
 */
export function addWarning(errorMessage: string, validation: SchemaValidation) {
  validation.valid = false
  validation.warnings.push(errorMessage)
}
