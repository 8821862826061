import { Controller } from '@hotwired/stimulus'

export default class PasswordInputController extends Controller<HTMLFormElement> {
  static targets = ['showPasswordIcon', 'hidePasswordIcon', 'clearPasswordIcon', 'passwordInput']

  showPassword() {
    this.passwordInputTarget.type = 'text'
    this.showPasswordIconTarget.style.display = 'none'
    this.hidePasswordIconTarget.style.display = 'inline-block'
  }

  hidePassword() {
    this.passwordInputTarget.type = 'password'
    this.showPasswordIconTarget.style.display = 'inline-block'
    this.hidePasswordIconTarget.style.display = 'none'
  }

  clearPassword() {
    this.passwordInputTarget.value = ''
    this.passwordInputTarget.type = 'password'
    this.showPasswordIconTarget.style.display = 'inline-block'
    this.hidePasswordIconTarget.style.display = 'none'

    const inputEvent = new Event('input')
    this.passwordInputTarget.dispatchEvent(inputEvent)
  }

  declare passwordInputTarget: HTMLInputElement
  declare showPasswordIconTarget: HTMLElement
  declare hidePasswordIconTarget: HTMLElement
  declare clearPasswordIconTarget: HTMLElement
}
