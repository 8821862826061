import { Controller } from '@hotwired/stimulus'

import { t } from 'translations/i18n'

// ######################################################
// # This feature is needed for C5 compliance for PSS-07
// ######################################################
const MIN_PASSWORD_LENGTH = 10

const CHARACTER_TYPES = [
  /[A-Z]/, // uppercase
  /[a-z]/, // lowercase
  /[0-9]/, // digit
  /[^A-Za-z0-9]/, // special
]

export default class PasswordValidationController extends Controller<HTMLFormElement> {
  static targets = [
    'currentPasswordInput',
    'passwordInput',
    'passwordConfirmation',
    'inputHelper',
    'confirmationHelper',
    'continueButton',
  ]

  onPasswordInputChange() {
    this.validatePassword()
    this.validatePasswordConfirmation()
    this.checkIfFormValid()
  }

  onPasswordConfirmationChange() {
    this.validatePasswordConfirmation()
    this.checkIfFormValid()
  }

  validatePassword() {
    if (this.passwordInputTarget.value.length > 0 && this.isPasswordValid()) {
      this.inputHelperTarget.textContent = t('devise.password_change.form.password_valid')
      this.setTextColor('text-success-700', this.inputHelperTarget)
      this.setBorderColor('border-info-600', this.passwordInputTarget)
    } else {
      this.inputHelperTarget.textContent = t('devise.password_change.form.password_invalid')
      this.setTextColor('text-error-500', this.inputHelperTarget)
      this.setBorderColor('border-error-500', this.passwordInputTarget)
    }
  }

  validatePasswordConfirmation() {
    // registration form does not have password confirmation field, so the check should be skipped
    if (this.hasPasswordConfirmationTarget) {
      if (this.passwordConfirmationTarget.value.length > 0 && this.passwordsMatch()) {
        this.confirmationHelperTarget.textContent = t('devise.password_change.form.password_match')
        this.setTextColor('text-success-700', this.confirmationHelperTarget)
        this.setBorderColor('border-info-600', this.passwordConfirmationTarget)
      } else {
        this.confirmationHelperTarget.textContent = t(
          'devise.password_change.form.password_mismatch',
        )
        this.setTextColor('text-error-500', this.confirmationHelperTarget)
        this.setBorderColor('border-error-500', this.passwordConfirmationTarget)
      }
    }
  }

  passwordsMatch() {
    // for registration form, this will be skipped and will always return true
    if (this.hasPasswordConfirmationTarget) {
      return this.passwordConfirmationTarget.value === this.passwordInputTarget.value
    } else {
      return true
    }
  }

  checkIfFormValid() {
    if (this.isCurrentPasswordPresent() && this.isPasswordValid() && this.passwordsMatch()) {
      this.continueButtonTarget.disabled = false
    } else {
      this.continueButtonTarget.disabled = true
    }
  }

  isCurrentPasswordPresent() {
    if (this.hasCurrentPasswordInputTarget && !(this.currentPasswordInputTarget.value.length > 0)) {
      return false
    }
    return true
  }

  isPasswordValid() {
    const password = this.passwordInputTarget.value

    return this.isLongEnough(password) && this.isComplexEnough(password)
  }

  // ######################################################
  // # This feature is needed for C5 compliance for PSS-07
  // ######################################################
  isLongEnough(password: string) {
    return password.length >= MIN_PASSWORD_LENGTH
  }

  // this function ensures that password has 3/4th of complexity
  // ######################################################
  // # This feature is needed for C5 compliance for PSS-07
  // ######################################################
  isComplexEnough(password: string) {
    return CHARACTER_TYPES.filter((regex) => regex.test(password)).length >= 3
  }

  setTextColor(color: string, element: HTMLElement) {
    element.classList.remove('text-info-750')
    element.classList.remove('text-success-700')
    element.classList.remove('text-error-500')

    element.classList.add(color)
  }

  setBorderColor(color: string, element: HTMLElement) {
    element.classList.remove('border-info-600')
    element.classList.remove('border-error-500')

    element.classList.add(color)
  }

  declare currentPasswordInputTarget: HTMLInputElement
  declare passwordInputTarget: HTMLInputElement
  declare passwordConfirmationTarget: HTMLInputElement
  declare inputHelperTarget: HTMLElement
  declare confirmationHelperTarget: HTMLElement
  declare continueButtonTarget: HTMLButtonElement
  declare hasPasswordConfirmationTarget: boolean
  declare hasCurrentPasswordInputTarget: boolean
}
