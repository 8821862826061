import _ from 'lodash'

import { JSX } from 'services/jsx/jsx-runtime'

import { intl, t } from '../../translations/i18n'
import { Section, MedicationItem } from '../../types/medicationPlan'

const SECTION_TITLE_BY_CODE_MAP: Record<string, string> = {
  411: t('medication_plan.section_title_codes.on_demand_medication'),
  412: t('medication_plan.section_title_codes.long_term_medication'),
  413: t('medication_plan.section_title_codes.intramuscular_application'),
  414: t('medication_plan.section_title_codes.special_application'),
  415: t('medication_plan.section_title_codes.intravenous_application'),
  416: t('medication_plan.section_title_codes.application_under_skin'),
  417: t('medication_plan.section_title_codes.prefill_syringe'),
  418: t('medication_plan.section_title_codes.self_medication'),
  419: t('medication_plan.section_title_codes.allergy_information'),
  421: t('medication_plan.section_title_codes.important_information'),
  422: t('medication_plan.section_title_codes.important_notes'),
  423: t('medication_plan.section_title_codes.medication_at_special_times'),
  424: t('medication_plan.section_title_codes.limited_time_medication'),
}

/**
 * Returns a BMP Section title.
 */
export function SectionTitleHtml({ section }: { section: Section }): JSX.Element {
  const { code, title } = section
  if (!title && !code) return null

  return (
    <h2 className="leading-6 text-x1 font-semibold text-neutral-750 my-2">
      {(code && SECTION_TITLE_BY_CODE_MAP[code]) || title}
    </h2>
  )
}

/**
 * Per BMP spec, checks text for a tilde character and splits it into two lines if found.
 */
export function WithLineBreaks({ text }: { text: string }): JSX.Element {
  // in the specification, the tilde character is used to indicate a line break
  // and can only be one so we ignore all other occurrences
  const lineBreakPosition = text.indexOf('~')
  const firstLine = lineBreakPosition > 0 ? text.slice(0, lineBreakPosition) : text
  const secondLine = lineBreakPosition > 0 ? text.slice(lineBreakPosition + 1) : null

  return (
    <span>
      {firstLine}
      {secondLine && <br />}
      {secondLine}
    </span>
  )
}

export function getActiveIngredientName(item: MedicationItem): string {
  return item.activeIngredients.length > 1
    ? _.map(item.activeIngredients, (activeIngredient) => {
        const name = activeIngredient.name || ''
        const strength = activeIngredient.strength || ''

        return strength !== null && strength !== undefined ? `${name} (${strength})` : name
      }).join(' / ')
    : item.activeIngredients[0]?.name || ''
}

export function getActiveIngredientStrength(item: MedicationItem): string {
  return _.map(item.activeIngredients, (activeIngredient) => activeIngredient.strength || '').join(
    ' / ',
  )
}

/**
 * Formats a string if length is 8, putting dashes in between the year, month and day.
 * Needed when parsing dates from BMP XML because yyyymmdd is not a valid date format for intl.formatDate.
 * Returns the string unchanged otherwise.
 */
export function formatDateString(dateString: string): string {
  if (dateString.length !== 8) return dateString

  try {
    return intl.formatDate(
      `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`,
    )
  } catch (error) {
    console.error('Error parsing date', dateString, error)
    return dateString
  }
}
