{
  "devise.password_change.form.password_invalid": "A palavra-passe não cumpre os requisitos",
  "devise.password_change.form.password_match": "As palavras-passe coincidem",
  "devise.password_change.form.password_mismatch": "As senhas não correspondem",
  "devise.password_change.form.password_valid": "A palavra-passe cumpre os requisitos",
  "document_upload.file_error_title": "O documento não pode ser carregado",
  "document_upload.file_type_error": "Verifique se o documento tem um formato de ficheiro compatível",
  "document_upload.upload_error": "Ocorreu um erro de rede durante o processo de carregamento. Tente carregar o documento novamente.",
  "document_upload.upload_error_title": "Ocorreu um erro.",
  "patient_forms.errors.invalid": "Um ou mais campos do formulário estão em falta ou têm um formato inválido. Corrija os campos assinalados a vermelho. Também pode guardar o formulário como rascunho, mas terá de o submeter mais tarde para que a sua clínica tenha acesso ao mesmo.",
  "patient_forms.errors.loading": "Ocorreu um erro ao carregar os valores dos campos existentes. Pode começar do zero ou contactar o suporte.",
  "patient_forms.errors.submitting": "A gravação falhou. Verifique a sua ligação à Internet e tente novamente numa altura posterior."
}
