import { utf8StringToBytes } from '@samedi/crypto-js/utils'
import { fromByteArray as encode64 } from 'base64-js'

import { OrbeonIframeWindow } from '../types/orbeon'

export class OrbeonFormInstanceWrapper {
  private orbeonIframeElement: HTMLIFrameElement

  constructor(orbeonIframeElement: HTMLIFrameElement) {
    this.orbeonIframeElement = orbeonIframeElement
  }

  async isValid() {
    await this.getCurrentFormDataPlain() // to trigger orbeon validation

    const orbeonFormDocument = this.orbeonIframeWindow().document
    const errorSummary = orbeonFormDocument.querySelector('.xbl-fr-error-summary')

    if (!errorSummary) return true

    const errors = errorSummary.querySelector('.fr-error-summary-body')
    return errors === null
  }

  async getCurrentFormDataEncodedString(): Promise<string> {
    const plainXmlData = await this.getCurrentFormDataPlain()
    const encodedData = { data: encode64(utf8StringToBytes(plainXmlData)) }

    return JSON.stringify(encodedData)
  }

  private async getCurrentFormDataPlain(): Promise<string> {
    return new Promise((resolve) => {
      this.subscribeToEvent('instanceUpdated', resolve)
      this.dispatchOrbeonEvent('si-update-instance')
    })
  }

  private subscribeToEvent(eventName: string, callback: (args: any) => void) {
    const iWindow = this.orbeonIframeWindow()

    const cb = function (_eventName: string, args: any) {
      iWindow.si.events[eventName].unsubscribe(cb)
      callback(args)
    }

    if (!(eventName in iWindow.si.events)) {
      throw new Error(`Unknown orbeon event "${eventName}" in si.events`)
    }

    iWindow.si.events[eventName].subscribe(cb)
  }

  private dispatchOrbeonEvent(eventName: string, targetId = 'fr-form-model') {
    const iWindow = this.orbeonIframeWindow()

    if (iWindow) {
      iWindow.ORBEON.xforms.Document.dispatchEvent({
        targetId,
        eventName,
      })
    }
  }

  private orbeonIframeWindow(): OrbeonIframeWindow {
    if (!this.orbeonIframeElement.contentWindow) {
      throw new Error('orbeon iframe is not found')
    }

    return this.orbeonIframeElement.contentWindow as OrbeonIframeWindow
  }
}
