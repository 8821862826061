import { Controller } from '@hotwired/stimulus'

import { generateAndUploadDeviceKeyPairForPassword } from '../encryption/encryption'

/**
 * EncryptionController allow the creation and set of the encryption keys
 */
export default class EncryptionPasswordController extends Controller<HTMLFormElement> {
  static targets = ['continueButton', 'passwordInput']

  static values = { redirectPath: String }

  async setPassword(event: Event) {
    event.stopPropagation()
    event.preventDefault()

    this.continueButtonTarget.disabled = true

    const password = this.passwordInputTarget.value
    const success = await generateAndUploadDeviceKeyPairForPassword(password)

    if (success) {
      window.location.assign(this.redirectPathValue)
    }
  }

  declare continueButtonTarget: HTMLButtonElement
  declare passwordInputTarget: HTMLInputElement

  declare redirectPathValue: string
}
