import { validateBMPXML } from '../../services/medicationPlan/schemaValidator/schemaValidator'
import { SchemaValidation } from '../../services/medicationPlan/schemaValidator/types'

export default function parseMedicationPlan(data: string): {
  document: Document | null
  validation: SchemaValidation
} {
  const parser = new DOMParser()
  const parsedData = parser.parseFromString(data, 'application/xml')
  const parsingError = hasParsingErrors(parsedData)

  if (parsingError) throw new Error(`Invalid BMP XML: ${parsingError}`)

  const validation = validateBMPXML(data)

  if (validation.errors.length > 0) throw new Error(`Invalid BMP XML: ${validation.errors}`)

  if (validation.warnings.length > 0) {
    console.warn('BMP XML has format errors: ', validation.warnings)
  }

  return { document: parsedData, validation }
}

function hasParsingErrors(parsedDocument: Document) {
  // In Firefox, the root element will be a <parsererror> if there's an error
  // https://developer.mozilla.org/en-US/docs/Web/API/DOMParser/parseFromString#error_handling
  if (parsedDocument.documentElement.nodeName === 'parsererror') {
    return parsedDocument.documentElement.textContent
  }

  // In other browsers, check for <parsererror> elements
  const parserErrors = parsedDocument.getElementsByTagName('parsererror')
  if (parserErrors.length > 0) {
    return parserErrors[0].textContent
  }

  return null
}
