import { Controller } from '@hotwired/stimulus'

/**
 * ClipboardController can be used to copy a value to the clipboard
 *
 * Usage:
 * <div data-controller="clipboard">
 *   <input type="text" value="Value to copy" data-clipboard-target="value">
 *   <button data-action="clipboard#copy">Copy</button>
 * </div>
 *
 * Usage with a div instead of an input:
 * <div data-controller="clipboard">
 *   <div data-clipboard-target="value">Value to copy</div>
 *   <button data-action="clipboard#copy">Copy</button>
 * </div>
 *
 */
export default class ClipboardController extends Controller {
  static targets = ['value']

  async copy() {
    let value: string = this.valueTarget.innerText
    if (this.valueTarget instanceof HTMLInputElement) {
      value = this.valueTarget.value
    }

    // Copy value to clipboard
    try {
      await navigator.clipboard.writeText(value)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  declare valueTarget: HTMLElement
}
