{
  "devise.password_change.form.password_invalid": "Le mot de passe ne répond pas aux exigences",
  "devise.password_change.form.password_match": "Les mots de passe correspondent",
  "devise.password_change.form.password_mismatch": "Les mots de passe ne correspondent pas",
  "devise.password_change.form.password_valid": "Le mot de passe est conforme aux exigences",
  "document_upload.file_error_title": "Le document ne peut pas être téléchargé",
  "document_upload.file_type_error": "Veuillez vérifier que le document a un format de fichier compatible",
  "document_upload.upload_error": "Une erreur de réseau s'est produite pendant le processus de téléchargement. Veuillez réessayer de télécharger le document.",
  "document_upload.upload_error_title": "Une erreur s'est produite",
  "patient_forms.errors.invalid": "Un ou plusieurs champs du formulaire sont manquants ou ont un format non valide. Veuillez corriger les champs marqués en rouge. Vous pouvez également enregistrer le formulaire en tant que brouillon, mais vous devrez le soumettre ultérieurement pour que votre cabinet y ait accès.",
  "patient_forms.errors.loading": "Une erreur s'est produite lors du chargement des valeurs des champs existants. Vous pouvez repartir de zéro ou contacter le service d'assistance.",
  "patient_forms.errors.submitting": "L'enregistrement a échoué. Veuillez vérifier votre connexion internet et réessayer ultérieurement."
}
