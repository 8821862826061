import { Controller } from '@hotwired/stimulus'

import { loadDeviceKeyFromSessionOnce } from '../encryption/encryption'
import downloadFileFromStream from '../services/downloadFileFromStream'
import getFileStream, { FileData, decryptFileMetadata } from '../services/downloadFiles'

import { MEDICATION_PLAN_KDL_CODE } from '../shared_constants'

/**
 * DownloadFileController allow to download files
 */
export default class DownloadFileController extends Controller<HTMLFormElement> {
  static values = {
    fileId: String,
    encryptionKeyData: String,
    encryptionKeyKeyPairId: String,
    encryptedMetadata: String,
    encryptedName: String,
  }

  static targets = ['fileName', 'title', 'showMedicationPlan']
  file?: FileData

  async connect() {
    await loadDeviceKeyFromSessionOnce()

    await this.decryptFileData()

    if (!this.file) {
      return
    }

    for (const target of this.fileNameTargets) {
      if (target.textContent?.trim() === '') {
        target.textContent = this.fileName
      }
    }

    if (this.hasTitleTarget && this.titleTarget.textContent?.trim() === '') {
      const title = this.fileDescription
      this.titleTarget.textContent = title
    }
  }

  async downloadFile(event: Event) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.file) {
      throw new Error('No file')
    }

    const { id, encryptionKey } = this.file
    const fileStream = await getFileStream(id, encryptionKey)

    downloadFileFromStream(fileStream, {
      filename: this.file.fileName || 'unkown-file-name',
      size: this.file.metaData.size,
    })
  }

  private async decryptFileData() {
    const data = await decryptFileMetadata(
      {
        patientKeyPairId: this.encryptionKeyKeyPairIdValue,
        encryptedData: this.encryptionKeyDataValue,
      },
      this.encryptedMetadataValue,
      this.encryptedNameValue,
    )

    this.detectMedicationPlan(data)

    this.file = {
      id: this.fileIdValue,
      ...data,
    }
  }

  detectMedicationPlan(data: Omit<FileData, 'id'>) {
    if (!this.hasShowMedicationPlanTarget) return

    if (data.metaData.kdlCode === MEDICATION_PLAN_KDL_CODE) {
      this.showMedicationPlanTarget.classList.remove('hidden')
    }
  }

  get fileDescription(): string {
    if (!this.file) {
      return ''
    }

    return this.file.metaData.fileDescription ? this.file.metaData.fileDescription : this.fileName
  }

  get fileName(): string {
    if (!this.file) {
      return ''
    }

    return this.file.fileName
  }

  declare fileIdValue: string
  declare encryptionKeyDataValue: string
  declare encryptionKeyKeyPairIdValue: string
  declare encryptedMetadataValue: string
  declare encryptedNameValue: string

  declare fileNameTargets: [HTMLElement]
  declare titleTarget: HTMLElement
  declare showMedicationPlanTarget: HTMLElement
  declare hasTitleTarget: Boolean
  declare hasShowMedicationPlanTarget: Boolean
}
