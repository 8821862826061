import { Controller } from '@hotwired/stimulus'

/**
 * InputPatternValidator restricts the entered characters to match a pattern
 * If a character is entered that does not match the pattern, the character is removed
 */
export default class InputPatternValidator extends Controller {
  connect() {
    const element = this.element as HTMLInputElement

    const pattern = element.getAttribute('pattern')
    if (!pattern) {
      throw new Error('InputPatternValidator requires a pattern attribute')
    }

    let currentValue = element.value

    element.addEventListener('input', () => {
      const value = element.value

      const isValid = new RegExp(pattern).test(value)

      if (isValid) {
        currentValue = value
      } else {
        element.value = currentValue
      }
    })
  }
}
