import { Controller } from '@hotwired/stimulus'
import { isValid, parse } from 'date-fns'

// Connects to data-controller="booking-wizard-selections"
export default class extends Controller {
  static values = {
    bornOn: String,
  }

  declare bornOnValue: string

  insuranceStep() {
    const select = this.element as HTMLSelectElement
    const selectedInsurance = select.value
    const params = new URL(window.location.toString()).searchParams
    const bornOnValue = this.bornOnValue
    const categorySelected =
      selectedInsurance && params.get('location_id') && params.get('appointment_category_id')
    const appointmentTypeSelected = categorySelected && params.get('appointment_type_id')
    const availabilitySelected = appointmentTypeSelected && params.get('availability_id')

    if (selectedInsurance) {
      if (!bornOnValue) {
        params.set('step', 'born_on')
      } else if (availabilitySelected) {
        params.set('step', 'confirmation')
      } else if (appointmentTypeSelected) {
        params.set('step', 'availability')
      } else if (categorySelected) {
        params.set('step', 'appointment_type')
      } else {
        params.set('step', 'category')
      }
    } else {
      params.set('step', 'insurance')
    }

    params.set('insurance_id', selectedInsurance)
    window.location.search = params.toString()
  }

  bornOnStep() {
    const daySelect = document.getElementById('date_day') as HTMLSelectElement
    const monthSelect = document.getElementById('date_month') as HTMLSelectElement
    const yearSelect = document.getElementById('date_year') as HTMLSelectElement
    const params = new URL(window.location.toString()).searchParams
    const bornOn: string[] = []

    bornOn[0] = daySelect.value
    bornOn[1] = monthSelect.value
    bornOn[2] = yearSelect.value

    // Wait until user selects all 3 date parts: day, month, year
    if (bornOn.filter(Boolean).length !== 3) {
      return
    }

    if (this.isValidBornOn(bornOn)) {
      params.set('born_on', bornOn.join('-'))
      window.location.search = params.toString()
    } else {
      params.delete('born_on')
      window.location.search = params.toString()
    }
  }

  private isValidBornOn(bornOn: string[]): boolean {
    const [day, month, year] = bornOn

    const parsedDate = parse(`${year}-${month}-${day}`, 'yyyy-M-d', new Date())

    return isValid(parsedDate) && parsedDate < new Date()
  }
}
