export interface ActiveIngredient {
  name: string // w attr
  strength: string // s attr
}

// M tag
export interface MedicationItem {
  type: 'medication'
  pzn: string // p attr
  // W tag
  activeIngredients: ActiveIngredient[]
  commercialName: string // a attr
  dosage: {
    morning: string // m attr
    noon: string // d attr
    evening: string // v attr
    night: string // h attr
    freeText: string // t attr
  }
  dosageUnitCode: string // du attr
  dosageUnitFreeText: string // dud attr
  doseForm: string // f attr
  doseFormFreeText: string // fd attr
  instructions: string // i attr
  reason: string // r reason
  boundAdditionalLine: string // x attr
}

// R tag
export interface RecipeItem {
  type: 'recipe'
  text: string // t attr
  boundAdditionalLine: string // x attr
}

// X tag
export interface FreetextItem {
  type: 'free_text'
  text: string // t attr
}

interface SectionWithTitle {
  title: string
  code?: never
  items: (MedicationItem | RecipeItem | FreetextItem)[]
}

interface SectionWithCode {
  code: string
  title?: never
  items: (MedicationItem | RecipeItem | FreetextItem)[]
}

// S tag
export type Section = SectionWithTitle | SectionWithCode

// P tag
export interface BMPPatient {
  firstName: string // g attr
  lastName: string // f attr
  insuranceId: string // egk attr
  birthDate: string // b attr
  gender: string // s attr
  title: string // t attr
  prefix: string // v attr
  sufix: string // z attr
}

// A tag
export interface BMPAdministrationBlock {
  printedBy: string // n attr
  doctorNumber: string // lanr attr
  pharmacyId: string // idf attr
  institution: string // kik attr
  street: string // s attr
  zipCode: string // z attr
  city: string // c attr
  phone: string // p attr
  email: string // e attr
  printDateTime: string // t attr
}

// O tag
export interface BMPRelevantPatientInformation {
  weight: string // w attr kgs
  height: string // h attr cms
  creatinine: string // c attr mg/dl
  allergiesIntolerances: string // ai attr
  breastfeeding: string // b attr
  pregnancy: string // p attr
  freetext: string // x attr
}

export interface BMP {
  patient: BMPPatient // P tag
  administrationBlock: BMPAdministrationBlock // A tag
  relevantPatientInformation: BMPRelevantPatientInformation // O tag
  sections: Section[] // S tags
}

export type ItemType = Section['items'][number]['type']

export const isMedicationItem = (
  item: MedicationItem | RecipeItem | FreetextItem,
): item is MedicationItem => item.type === 'medication'

export interface PharmaProduct {
  productName: string
  pzn: string
  doseForm: string
  validityArticleDataOn: string // ISO 8601 date
  activeIngredients: ActiveIngredient[]
}
