import { Controller } from '@hotwired/stimulus'

export default class PatientInvitationsController extends Controller {
  static targets = ['tokenTextField', 'submitButton']

  declare tokenTextFieldTarget: HTMLInputElement
  declare submitButtonTarget: HTMLButtonElement

  connect() {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState() {
    this.submitButtonTarget.disabled = !this.tokenTextFieldTarget.value
  }
}
