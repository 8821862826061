import { Controller } from '@hotwired/stimulus'

export default class ExpandableController extends Controller {
  static targets = ['content', 'hide', 'show']

  declare contentTarget: HTMLElement
  declare hideTargets: HTMLElement[]
  declare showTargets: HTMLElement[]

  isExpanded: boolean = false
  lineClampClass: string = 'line-clamp-5'

  connect() {
    this.registerUsedLineClampClass()
    window.addEventListener('resize', this.verifyLineClampActive.bind(this))
    this.verifyLineClampActive()
  }

  disconnect() {
    window.removeEventListener('resize', this.verifyLineClampActive.bind(this))
  }

  toggle() {
    if (this.isExpanded) {
      this.collapseContent()
    } else {
      this.expandContent()
    }
  }

  private registerUsedLineClampClass() {
    for (const name of this.contentTarget.classList.values()) {
      if (name.startsWith('line-clamp-')) {
        this.lineClampClass = name
        return
      }
    }
  }

  private verifyLineClampActive() {
    // If it is expanded, we cannot easily check for that
    if (!this.isExpanded) {
      if (this.isExpandable()) {
        this.enableToggles()
      } else {
        this.disableToggles()
      }
    }
  }

  private expandContent() {
    this.isExpanded = true
    this.contentTarget.classList.remove(this.lineClampClass)
    this.hideTargets.forEach((element) => {
      element.classList.remove('hidden')
    })
    this.showTargets.forEach((element) => {
      element.classList.add('hidden')
    })
  }

  private collapseContent() {
    this.isExpanded = false
    this.contentTarget.classList.add(this.lineClampClass)
    this.hideTargets.forEach((element) => {
      element.classList.add('hidden')
    })
    this.showTargets.forEach((element) => {
      element.classList.remove('hidden')
    })
  }

  private enableToggles() {
    if (this.isExpanded) {
      this.hideTargets.forEach((element) => {
        element.classList.remove('hidden')
      })
    } else {
      this.showTargets.forEach((element) => {
        element.classList.remove('hidden')
      })
    }
  }

  private disableToggles() {
    ;[...this.hideTargets, ...this.showTargets].forEach((element) =>
      element.classList.add('hidden'),
    )
  }

  private isExpandable() {
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight
  }
}
