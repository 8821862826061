import { Controller } from '@hotwired/stimulus'

import { hashPassword } from '../encryption/encryption'

/**
 * LoginController
 */
export default class PasswordHasingController extends Controller<HTMLFormElement> {
  static targets = ['email', 'password']

  async hashPassword(event: CustomEvent) {
    event.preventDefault()

    const hashedPassword = await hashPassword(this.emailTarget.value, this.passwordTarget.value)
    event.detail.fetchOptions.body.set('patient_user[password]', hashedPassword)

    event.detail.resume()
  }

  declare passwordTarget: HTMLInputElement
  declare emailTarget: HTMLInputElement
}
