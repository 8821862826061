import { createIntl, createIntlCache } from '@formatjs/intl'

import de from './de.json'
import en from './en.json'
import es from './es.json'
import fi from './fi.json'
import fr from './fr.json'
import pt from './pt.json'
import ru from './ru.json'
import tr from './tr.json'

const cache = createIntlCache()

const messages = { de, en, es, fi, fr, pt, ru, tr }
const availableLanguages = Object.keys(messages) as (keyof typeof messages)[]

const candidateLocales = [
  document.documentElement?.lang,
  ...navigator.languages,
  navigator.language,
]
  .filter(Boolean)
  .map((localeDescriptor) => new Intl.Locale(localeDescriptor))
  .filter((locale) => locale.language)
const locale =
  candidateLocales.find((locale) => availableLanguages.includes(locale.language as any)) ||
  new Intl.Locale('de')

const intl = createIntl(
  {
    locale: locale.toString(),
    messages: messages[locale.language as keyof typeof messages] || messages.de,
  },
  cache,
)

type FormatMessageParams = Parameters<(typeof intl)['formatMessage']>
const t = (
  scope: FormatMessageParams[0]['id'],
  values?: FormatMessageParams[1],
  opts?: FormatMessageParams[2],
): string => {
  const msg = intl.formatMessage({ id: scope }, values, opts)
  return Array.isArray(msg) ? msg.join('') : msg
}

export { intl, t }
