import { Controller } from '@hotwired/stimulus'

import { fromByteArray } from 'base64-js'

import {
  generateAndUploadDeviceKeyPairForRecoveryToken,
  existRecoveryToken,
  loadDeviceKeyFromSessionOnce,
} from '../encryption/encryption'

export default class RecoveryTokenController extends Controller<HTMLFormElement> {
  static targets = [
    'continueButtonLinkEnabled',
    'continueButtonLinkDisabled',
    'recoveryTokenContainer',
    'saveConfirmationCheckbox',
  ]

  async connect() {
    loadDeviceKeyFromSessionOnce()
    if (!(await existRecoveryToken())) {
      const recoveryToken = fromByteArray(crypto.getRandomValues(new Uint8Array(16)))
      await generateAndUploadDeviceKeyPairForRecoveryToken(recoveryToken)

      this.recoveryTokenContainerTarget.textContent = recoveryToken
    }
  }

  onCheckboxChanged() {
    if (this.saveConfirmationCheckboxTarget.checked) {
      this.continueButtonLinkDisabledTarget.classList.add('hidden')
      this.continueButtonLinkEnabledTarget.classList.remove('hidden')
    } else {
      this.continueButtonLinkDisabledTarget.classList.remove('hidden')
      this.continueButtonLinkEnabledTarget.classList.add('hidden')
    }
  }

  declare continueButtonLinkDisabledTarget: HTMLButtonElement
  declare continueButtonLinkEnabledTarget: HTMLButtonElement
  declare recoveryTokenContainerTarget: HTMLElement
  declare saveConfirmationCheckboxTarget: HTMLInputElement
}
