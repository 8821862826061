import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="progressbar"
export default class extends Controller {
  static targets = ['fullfilled', 'optional']
  static values = { fullfilled: String, optional: String }

  declare fullfilledTarget: HTMLElement
  declare optionalTarget: HTMLElement
  declare fullfilledValue: string
  declare optionalValue: string

  connect() {
    this.fullfilledTarget.style.width = this.fullfilledValue
    this.optionalTarget.style.width = this.optionalValue
  }
}
