import sanitize from 'sanitize-html'

interface SanitizeOptions {
  additionalAllowedTags?: string[]
}

/**
 * injectLinksToTextMessage loops through a string finding url candidates,
 * replacing them with the proper <a> tag
 *
 * This method is copied and refactored from the Platform project.
 * https://git.samedi.cc/dev/platform/-/blob/master/app/javascript/components/shared/InjectLinksToTextMessage.tsx
 */
export const injectLinksToTextMessage = (message: string) => {
  if (message.length === 0) {
    return message
  }

  // find urls not inside <a> tag
  // extended version of
  // https://regex101.com/r/pC0jR7/2
  const urlsNotInAnchorTagRegex =
    /(((https?:\/\/)|[a-z]{3,3}[.]?)[^"<\s]+)?([a-z]*[.][a-z]{2,}[.a-z0-9_/#]*)(?![^<>]*>|[^"]*?<\/a)/gi
  const result: string[] = []
  let url: string[] | null
  let currentPointer = 0
  let i = 0

  while ((url = urlsNotInAnchorTagRegex.exec(message)) !== null) {
    const startOfUrl = urlsNotInAnchorTagRegex.lastIndex - url[0].length
    const contentBeforeUrl = message.slice(currentPointer, startOfUrl)
    result.push(contentBeforeUrl)
    // add protocol to URLs that done have it, e.g. "www.samedi.de"
    const urlHref = url[0].startsWith('http') ? url[0] : `https://${url[0]}`
    result.push(
      `<a key="link-fragment-${i++}" target="_blank" rel="noreferrer nofollow noopener external" href="${urlHref}">${url[0]}</a>`,
    )
    // move to end of last url detected
    currentPointer = urlsNotInAnchorTagRegex.lastIndex
  }

  // add the last part
  const contentAfterLastUrl = message.slice(currentPointer)
  result.push(contentAfterLastUrl)

  return result.join('')
}

/**
 * This method is copied and refactored from the Platform project.
 * https://git.samedi.cc/dev/platform/-/blob/master/app/javascript/services/messages/sanitizeMessageBody.ts
 */
export const sanitizeMessageBody = (
  body: string,
  { additionalAllowedTags = [] }: SanitizeOptions = {},
) => {
  return sanitize(body.replace(/\n/g, '<br />'), {
    allowedTags: ['b', 'i', 'u', 'em', 'strong', 'a', 'br', 'p', ...additionalAllowedTags],
    allowedAttributes: { a: ['href'] },
    allowedSchemes: ['http', 'https'],
  })
}
