import { BMPSchemaDefinition } from './types'
import { xml2json } from './xml2json'

// transforms a BMP XML Schema Definition file into a json object
export function xsdToJsonParser(bmpSchema: string): BMPSchemaDefinition {
  const withoutXsNamespace = bmpSchema.replace(/<xs:/g, '<').replace(/<\/xs:/g, '</')
  const parser = new DOMParser()
  const xsdDoc = parser.parseFromString(withoutXsNamespace, 'text/xml')
  const errors = xsdDoc.getElementsByTagName('parsererror')

  if (errors.length > 0) {
    throw new Error(`Error parsing bmpSchema. ${errors[0].textContent}`)
  }

  return xml2json(xsdDoc, '  ')
}
