import { EncryptedSigningKey, Signer } from '@samedi/crypto-js/signer'

import { getCurrentDeviceKey, getMasterKey, CSRFToken } from './encryption'
import { importPatientUserMasterKey } from './keyHandling'

interface APISigningKey {
  signature_verification_key_id: string
  private_key_encrypted: string
  patient_user_master_key_encrypted: string
}

async function fetchAllSigningKeys(deviceKeyId: string): Promise<APISigningKey[]> {
  const params = new URLSearchParams({ device_key_id: deviceKeyId })
  const request = await fetch(`/signing_keys?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  const response = await request.json()

  return response.data
}

async function fetchSigningKey(): Promise<
  { masterKey: CryptoKey; encryptedSigningKey: EncryptedSigningKey } | undefined
> {
  const deviceKey = getCurrentDeviceKey()
  const encryptedSigningKeys = await fetchAllSigningKeys(deviceKey.id)

  for (const key of encryptedSigningKeys) {
    try {
      const masterKey = await importPatientUserMasterKey(
        deviceKey.key.privateKey,
        key.patient_user_master_key_encrypted,
      )

      return {
        masterKey,
        encryptedSigningKey: {
          keyId: key.signature_verification_key_id,
          encryptedPrivateKey: key.private_key_encrypted,
        },
      }
    } catch (e) {
      console.error('Error decrypting a signing keys master key', e)
    }
  }
}

function generateSaveSigningKeyFunction(masterKeyId: string) {
  return async (privateKey: string, publicKey: string): Promise<{ keyId: string }> => {
    const requestBody = {
      signing_key: {
        patient_user_master_key_id: masterKeyId,
        private_key_encrypted: privateKey,
        public_key_jwk: JSON.parse(publicKey),
      },
    }

    const request = await fetch('/signing_keys', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRFToken(),
      },
      body: JSON.stringify(requestBody),
    })

    const response = await request.json()

    return { keyId: response.signature_verification_key_id }
  }
}

export async function buildSigner(): Promise<Signer> {
  const existingSigningKeyData = await fetchSigningKey()

  if (existingSigningKeyData) {
    return Signer.buildWithCryptoKey(
      existingSigningKeyData.masterKey,
      undefined,
      existingSigningKeyData.encryptedSigningKey,
    )
  } else {
    const masterKey = await getMasterKey()
    const saveSigningKey = generateSaveSigningKeyFunction(masterKey.id)
    return Signer.buildWithCryptoKey(masterKey.key, saveSigningKey, undefined)
  }
}
