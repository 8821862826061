import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="locale"
export default class extends Controller {
  switch(event: Event) {
    if (event.target instanceof HTMLSelectElement) {
      const newLocale = event.target?.value
      window.location.href = `/locales/${newLocale}`
    }
  }
}
