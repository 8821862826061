import _ from 'lodash'

import { JSX } from 'services/jsx/jsx-runtime'

import { t } from '../../translations/i18n'
import {
  BMP,
  MedicationItem,
  RecipeItem,
  FreetextItem,
  BMPPatient,
  Section,
  BMPAdministrationBlock,
} from '../../types/medicationPlan'

import { dosageUnitMappings } from './dosageUnitMappings'
import { doseFormMappings } from './doseFormMappings'
import { SectionTitleHtml, WithLineBreaks, formatDateString } from './helpers'
import { PrintVersion } from './printVersion'

export function warningMessage(): HTMLElement {
  return (
    <div className="bg-warning-100 text-warning-800 border-warning-200 border-2 rounded p-4 flex mb-4 items-center">
      <i className="mr-2 fa-regular fa-circle-exclamation" />
      <p className="text-warning-800">{t('medication_plan.warning_message')}</p>
    </div>
  )!
}

export function errorMessage(): HTMLElement {
  return (
    <div className="bg-error-100 text-error-800 border-error-200 border-2 rounded p-4 flex items-center">
      <i className="mr-2 fa-regular fa-circle-exclamation" />
      <p className="text-error-800">{t('medication_plan.error_message')}</p>
    </div>
  )!
}

function PanelToggleCheckbox({ id }: { id: string }): JSX.Element {
  return (
    <input
      type="checkbox"
      className="peer hidden"
      id={id}
      data-medication-plan-accordion-toggle-target="checkboxToggle"
      data-action="click->medication-plan-accordion-toggle#toggleOne"
    />
  )
}

// eslint-disable-next-line complexity
function EntryContentHtml({ item }: { item: MedicationItem }): JSX.Element {
  const dividerStyle = 'md:border-r-2 md:border-neutral-100 px-2 mr-2'
  const lastChildStyle = 'px-1'

  const activeIngredientStrength = _.map(item.activeIngredients, (activeIngredient) => {
    return activeIngredient.strength || ''
  }).join(' / ')

  return (
    <div className="text-neutral-750 hidden space-y-1 mt-2 pt-2 border-t-2 border-neutral-100 peer-checked:block">
      <div className="flex flex-col md:flex-row">
        <LabelledValue label={t('medication_plan.labels.strength')} valueClasses={dividerStyle}>
          {activeIngredientStrength}
        </LabelledValue>
        <LabelledValue label={t('medication_plan.labels.dose_form')} valueClasses={dividerStyle}>
          {item.doseFormFreeText || doseFormMappings(item.doseForm)}
        </LabelledValue>
        <LabelledValue
          label={t('medication_plan.labels.dosage_unit')}
          valueClasses={lastChildStyle}
        >
          {item.dosageUnitFreeText || dosageUnitMappings(item.dosageUnitCode)}
        </LabelledValue>
      </div>
      {item.dosage.freeText ? (
        <div className="flex">
          <LabelledValue
            label={t('medication_plan.labels.dosage_free_text')}
            valueClasses={lastChildStyle}
          >
            {item.dosage.freeText}
          </LabelledValue>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row">
          <LabelledValue
            label={t('medication_plan.labels.dosage_morning')}
            valueClasses={dividerStyle}
          >
            {item.dosage.morning}
          </LabelledValue>
          <LabelledValue
            label={t('medication_plan.labels.dosage_noon')}
            valueClasses={dividerStyle}
          >
            {item.dosage.noon}
          </LabelledValue>
          <LabelledValue
            label={t('medication_plan.labels.dosage_evening')}
            valueClasses={dividerStyle}
          >
            {item.dosage.evening}
          </LabelledValue>
          <LabelledValue
            label={t('medication_plan.labels.dosage_night')}
            valueClasses={lastChildStyle}
          >
            {item.dosage.night}
          </LabelledValue>
        </div>
      )}
      <div className="flex">
        <LabelledValue
          label={t('medication_plan.labels.instructions')}
          valueClasses={lastChildStyle}
        >
          {item.instructions || ''}
        </LabelledValue>
      </div>
      <div className="flex">
        <LabelledValue label={t('medication_plan.labels.reason')} valueClasses={lastChildStyle}>
          {item.reason || ''}
        </LabelledValue>
      </div>
    </div>
  )
}

function EntryHeader({ item, panelId }: { item: MedicationItem; panelId: string }): JSX.Element {
  const activeIngredientName =
    item.activeIngredients.length > 1
      ? _.map(item.activeIngredients, (activeIngredient) => {
          const name = activeIngredient.name || ''
          const strength = activeIngredient.strength || ''

          return strength !== null && strength !== undefined ? `${name} (${strength})` : name
        })
      : item.activeIngredients[0]?.name || ''

  const title = `${t('medication_plan.labels.active_ingredient')}: ${activeIngredientName}`

  return (
    <label
      htmlFor={panelId}
      className="flex flex-grow cursor-pointer peer-checked:[&_.open]:hidden peer-checked:[&_.close]:block"
    >
      <h2 className="flex flex-grow text-card-title font-semibold text-dark">{title}</h2>
      <i className="open far fa-chevron-down flex-none text-neutral-750" aria-label="open" />
      <i
        className={`close far fa-chevron-up flex-none text-neutral-750 hidden`}
        aria-label="close"
      />
    </label>
  )
}

function EntrySubheader({ item }: { item: MedicationItem }): JSX.Element {
  return (
    <div className="flex">
      <LabelledValue label={t('medication_plan.labels.commercial_name')} labelClasses="pr-1">
        {item.commercialName || ''}
      </LabelledValue>
    </div>
  )
}

function LabelledValue({
  label,
  children,
  labelClasses = '',
  valueClasses = '',
}: {
  label: string
  children: JSX.Children
  labelClasses?: string
  valueClasses?: string
}): JSX.Element {
  return (
    <dl className="flex">
      <dt className={`font-semibold text-info-800 ${labelClasses}`}>{label}:</dt>
      <dd className={`flex text-neutral-750 ${valueClasses}`}>{children}</dd>
    </dl>
  )
}

function BoundAdditionalLine({ item }: { item: MedicationItem | RecipeItem }): JSX.Element {
  if (!item.boundAdditionalLine) return null

  return (
    <div className="rounded bg-info-150 text-dark p-2">
      <WithLineBreaks text={item.boundAdditionalLine} />
    </div>
  )
}

function MedicationEntry({ item, id }: { item: MedicationItem; id: number }): JSX.Element {
  const panelId = `item_${id}_toggle`
  return (
    <div className="mb-3 rounded bg-white px-4 py-3 border-b-neutral-200 border-b-2">
      <PanelToggleCheckbox id={panelId} />
      <EntryHeader item={item} panelId={panelId} />
      <EntrySubheader item={item} />
      <EntryContentHtml item={item} />
      <BoundAdditionalLine item={item} />
    </div>
  )
}

function RecipeEntry({ item }: { item: RecipeItem }): JSX.Element {
  return (
    <div className="mb-3 rounded bg-white px-4 py-3 border-b-neutral-200 border-b-2 text-dark font-semibold">
      <WithLineBreaks text={item.text || ''} />
      <BoundAdditionalLine item={item} />
    </div>
  )
}

function FreeTextEntry({ item }: { item: FreetextItem }): JSX.Element {
  return (
    <div className="mb-3 px-4 py-3 text-dark font-semibold">
      <WithLineBreaks text={item.text || ''} />
    </div>
  )
}

function Entry({
  item,
  id,
}: {
  item: MedicationItem | RecipeItem | FreetextItem
  id: number
}): JSX.Element {
  if (!item) return null

  switch (item.type) {
    case 'medication':
      return <MedicationEntry item={item} id={id} />
    case 'recipe':
      return <RecipeEntry item={item} />
    case 'free_text':
      return <FreeTextEntry item={item} />
    default:
      console.error('Unknown item type', (item as any)?.type)
      return null
  }
}

function PatientName({ patient }: { patient: BMPPatient }): JSX.Element {
  return (
    <div className="flex flex-col gap-2 text-white text-ml font-semibold leading-6 mb-1 border-b border-b-info-200">
      {patient.firstName + ' ' + patient.lastName}
    </div>
  )
}

function HeaderLine({ value, label }: { value: string; label?: string }): JSX.Element {
  if (!value) return null

  return (
    <div className="flex gap-2 leading-m text-primary-100 text-base">
      {typeof label !== 'undefined' && <span className="font-semibold">{label}:</span>}
      <span className="font-normal">{value}</span>
    </div>
  )
}

function Address({
  administrationBlock,
}: {
  administrationBlock: BMPAdministrationBlock
}): JSX.Element {
  const { street, zipCode, city } = administrationBlock
  return (
    <div className="flex gap-2 leading-m text-primary-100 text-base">
      <span className="font-normal">{street},</span>
      <span>{zipCode}</span>
      <span>{city}</span>
    </div>
  )
}

function Header({ bmp }: { bmp: BMP }): JSX.Element {
  const { patient, administrationBlock, relevantPatientInformation } = bmp
  const { printedBy, institution, phone, email, printDateTime } = administrationBlock
  const { birthDate, gender, insuranceId } = patient
  const { weight, height, creatinine, allergiesIntolerances, breastfeeding, pregnancy, freetext } =
    relevantPatientInformation

  return (
    <div className="m-2 lg:basis-1/3 lg:order-2 lg:w-2/5 grow-0 h-fit print:hidden bg-dark text-white rounded shadow mb-6 overflow-hidden px-4 py-2">
      <PatientName patient={patient} />
      <HeaderLine label={t('medication_plan.labels.printed_by')} value={printedBy} />
      <HeaderLine value={institution} />
      <HeaderLine label={t('medication_plan.labels.phone')} value={phone} />
      <HeaderLine label={t('medication_plan.labels.email')} value={email} />
      <Address administrationBlock={administrationBlock} />
      <HeaderLine
        label={t('medication_plan.labels.patient_birthdate')}
        value={formatDateString(birthDate)}
      />
      <HeaderLine label={t('medication_plan.labels.gender')} value={gender} />
      <HeaderLine label={t('medication_plan.labels.insurance_number')} value={insuranceId} />
      <HeaderLine
        label={t('medication_plan.labels.weight')}
        value={weight && `${weight} ${t('medication_plan.labels.kgs')}`}
      />
      <HeaderLine
        label={t('medication_plan.labels.height')}
        value={height && `${height} ${t('medication_plan.labels.cms')}`}
      />
      <HeaderLine
        label={t('medication_plan.labels.creatinine')}
        value={creatinine && `${creatinine} ${t('medication_plan.labels.mg_dl')}`}
      />
      <HeaderLine
        label={t('medication_plan.labels.allergies_intolerances')}
        value={allergiesIntolerances}
      />
      {/* for pregnancy and breastfeeding, the values are the translations */}
      {breastfeeding === '1' && <HeaderLine value={t('medication_plan.labels.breastfeeding')} />}
      {pregnancy === '1' && <HeaderLine value={t('medication_plan.labels.pregnancy')} />}
      <HeaderLine value={freetext} />
      <HeaderLine
        label={t('medication_plan.labels.printed_on')}
        value={formatDateString(printDateTime)}
      />
    </div>
  )
}

function BMPSections({ sections }: { sections: Section[] }): JSX.Element {
  let entryId = 0

  return (
    <div className="lg:flex-col lg:basis-2/3 lg:order-1 lg:w-3/5 print:hidden">
      {sections.map((section) => (
        <div>
          <SectionTitleHtml section={section} />
          {section.items.map((item) => (
            <Entry item={item} id={entryId++} />
          ))}
        </div>
      ))}
    </div>
  )
}

export function buildMedicationPlanHTML(
  parsedBmp: BMP,
  rawBmpXml: string,
): NonNullable<JSX.Element> {
  const { sections } = parsedBmp

  return (
    <div className="h-full flex flex-col gap-10 lg:gap-16 lg:flex-row">
      <Header bmp={parsedBmp} />
      <BMPSections sections={sections} />
      <PrintVersion parsedBmp={parsedBmp} rawBmpXml={rawBmpXml} />
    </div>
  )!
}
