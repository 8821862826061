{
  "devise.password_change.form.password_invalid": "La contraseña no cumple los requisitos",
  "devise.password_change.form.password_match": "Las contraseñas coinciden",
  "devise.password_change.form.password_mismatch": "Las contraseñas no coinciden",
  "devise.password_change.form.password_valid": "La contraseña cumple los requisitos",
  "document_upload.file_error_title": "El documento no se puede cargar",
  "document_upload.file_type_error": "Compruebe que el documento tiene un formato de archivo compatible",
  "document_upload.upload_error": "Se ha producido un error de red durante el proceso de carga. Intente cargar el documento de nuevo.",
  "document_upload.upload_error_title": "Se ha producido un error",
  "patient_forms.errors.invalid": "Faltan uno o más campos del formulario o tienen un formato no válido. Por favor, corrija los campos marcados en rojo. También puede guardar el formulario como borrador, pero tendrá que enviarlo más tarde para que su consulta tenga acceso a él.",
  "patient_forms.errors.loading": "Se ha producido un error al cargar los valores de campo existentes. Puede empezar desde cero o ponerse en contacto con el servicio de asistencia.",
  "patient_forms.errors.submitting": "Error al guardar. Compruebe su conexión a Internet y vuelva a intentarlo más tarde."
}
