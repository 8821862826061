import { createSymmetricEncryptStream } from '@samedi/crypto-js/cryptor'

import { EncryptionKey, PatientPublicKey, encryptText } from '../encryption/encryption'
import {
  generateSharedEncryptionKey,
  shareEncryptionKeyWithInstitution,
} from '../encryption/encryptionKeyHandling'
import { exportEncryptionKeyInRawFormat } from '../encryption/keyHandling'
import { MEDICATION_PLAN_KDL_CODE } from '../shared_constants'

import ChunkedFileUploader from './ChunkedFileUploader'

import getReadableStream from './getReadableStream'

function chunkedFileUploaderFactory(
  file: File,
  percentageCallback?: (arg0: number, arg1: number) => void,
) {
  const estimatedEncryptedTotalFileSize = file.size * (1 + 0.25)

  let percentage = 0

  return new ChunkedFileUploader((encryptedChunkSize, chunkPosition) => {
    const totalChunks = estimatedEncryptedTotalFileSize / encryptedChunkSize / 10
    const currentPercentage = (chunkPosition / totalChunks) * 100

    if (currentPercentage > percentage && percentageCallback) {
      percentage = Math.round((chunkPosition / totalChunks) * 100)
      percentage > 100
        ? percentageCallback(100, file.size)
        : percentageCallback(percentage, file.size)
    }
  })
}

async function uploadThroughEncryptedStream(
  file: File,
  fileDescription: string | null,
  additionalMetaData: Record<string, string>,
  encryptionKey: EncryptionKey,
  documentRequestId: string | null,
  percentageCallback?: (arg0: number, arg1: number) => void,
) {
  const metaData = {
    file_name: file.name,
    file_size: file.size,
    file_type: file.type,
    file_description: fileDescription,
    ...additionalMetaData,
  }

  const isEncoded = additionalMetaData['kdl_code'] === MEDICATION_PLAN_KDL_CODE
  const rawKey = await exportEncryptionKeyInRawFormat(encryptionKey.key)

  const decryptedAESEncryptionKey = { plain: new Uint8Array(rawKey), webCrypto: undefined }
  const key = { id: encryptionKey.id, key: decryptedAESEncryptionKey }
  const encryptedMetaData = await encryptText(JSON.stringify(metaData), key)
  const encryptedFileName = await encryptText(file.name, key)

  const chunkedFileUploader = chunkedFileUploaderFactory(file, percentageCallback)

  const encryptStream = createSymmetricEncryptStream(key, chunkedFileUploader.storeChunk)

  const readableStream = await getReadableStream(file, isEncoded)

  await readableStream.pipeTo(encryptStream)
  await chunkedFileUploader.finalize()

  return chunkedFileUploader.createFile(encryptedFileName, encryptedMetaData, documentRequestId)
}

export default async function uploadFile(
  file: File,
  fileDescription: string | null,
  additionalMetaData: Record<string, string>,
  patientPublicKey: PatientPublicKey,
  documentRequestId: string | null,
  institutionKey?: any,
  percentageCallback?: (arg0: number, arg1: number) => void,
) {
  const encryptionKey = await generateSharedEncryptionKey(patientPublicKey)
  const fileId = await uploadThroughEncryptedStream(
    file,
    fileDescription,
    additionalMetaData,
    encryptionKey,
    documentRequestId,
    percentageCallback,
  )

  if (institutionKey) {
    await shareEncryptionKeyWithInstitution(encryptionKey, institutionKey)
  }

  return fileId
}
