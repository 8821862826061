import { Controller } from '@hotwired/stimulus'
import defaults from 'lodash/defaults'

/**
 * CameraControlsController activates and deactivates video camera controls
 * based on browser features. It requires for `detectFeatures` to be called,
 * e.g. by connecting an event to it.
 *
 * When the browser does not support the controls (currently only zoom), then
 * this controller hides itself.
 */
export default class CameraControlsController extends Controller {
  static targets = ['zoomOut', 'zoomIn']

  stream?: MediaStream
  track?: MediaStreamTrack

  connect() {
    this.zoomInTarget.addEventListener('click', this.zoomIn)
    this.zoomOutTarget.addEventListener('click', this.zoomOut)
  }

  disconnect() {
    this.zoomInTarget.removeEventListener('click', this.zoomIn)
    this.zoomOutTarget.removeEventListener('click', this.zoomOut)
  }

  zoomIn = (event: MouseEvent) => {
    event.preventDefault() // Prevent zooming on double click

    if (!this.track) return
    const { max, step } = this.getZoomRange()
    const value = Math.min(this.getZoomValue() + step, max)

    this.track
      .applyConstraints({ advanced: [{ zoom: value }] })
      .catch((error) => console.error('Applying constraints failed:', error))
  }

  zoomOut = (event: MouseEvent) => {
    event.preventDefault() // Prevent zooming on double click

    if (!this.track) return
    const { min, step } = this.getZoomRange()
    const value = Math.max(this.getZoomValue() - step, min)

    this.track
      .applyConstraints({ advanced: [{ zoom: value }] })
      .catch((error) => console.error('Applying constraints failed:', error))
  }

  detectFeatures({ detail: { stream } }: { detail: { stream: MediaStream } }) {
    this.stream = stream
    this.track = stream.getVideoTracks()[0]

    const settings = this.track.getSettings()

    if ('zoom' in settings) {
      this.show()
      this.setInitialZoom()
    } else {
      this.hide()
    }
  }

  show() {
    this.element.classList.remove('hidden')
  }

  hide() {
    this.element.classList.add('hidden')
  }

  setInitialZoom(): void {
    const range = this.getZoomRange()
    if (!range) {
      this.hide()
      console.error('Zoom is not supported, but was tried to initialize.')
      return
    }

    if (this.getZoomValue() <= range.min) {
      // Initialize the zoom at 1/3 of the range, so that it does not start in wide-angle mode.
      const initialValue = range.min + (range.max - range.min) / 3
      this.track?.applyConstraints({ advanced: [{ zoom: initialValue }] })
    }
  }

  getZoomRange(): { min: number; max: number; step: number } {
    if (!this.track) return { min: 1, max: 1, step: 1 }
    const track = this.track
    const zoom = track.getCapabilities().zoom!
    return defaults({}, zoom, { min: 1, max: 2, step: (zoom.max - zoom.min) / 4 || 0.5 })
  }

  getZoomValue(): number {
    if (!this.track) return 1

    const settings = this.track.getSettings()
    return settings.zoom || 1
  }

  declare zoomOutTarget: HTMLButtonElement
  declare zoomInTarget: HTMLButtonElement
}
