// validators for the different types of BMPRestrictions

import { addWarning } from './helpers'
import { SchemaValidation, BMPElement, BMPAttribute, BMPRestriction } from './types'

type IntegerRestriction =
  | 'length'
  | 'maxLength'
  | 'minLength'
  | 'fractionDigits'
  | 'maxExclusive'
  | 'minExclusive'
  | 'maxInclusive'
  | 'minInclusive'

function getIntegerValue(restriction: BMPRestriction[IntegerRestriction]): number {
  return parseInt(restriction?.['@value'] || '0', 10)
}

function validateLength(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['length'],
) {
  const length = getIntegerValue(restriction)
  if (value && value.length !== length) {
    addWarning(
      `Length of ${attributeDefinition['@name']} in ${elementDefinition['@name']} must be ${length}`,
      validation,
    )
  }
}

function validateMaxLength(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['maxLength'],
) {
  const maxLength = getIntegerValue(restriction)
  if (value && value.length > maxLength) {
    addWarning(
      `Length of ${attributeDefinition['@name']} in ${elementDefinition['@name']} must be equal or less than ${maxLength}`,
      validation,
    )
  }
}

function validateMinLength(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['minLength'],
) {
  const minLength = getIntegerValue(restriction)
  if (value && value.length < minLength) {
    addWarning(
      `Length of ${attributeDefinition['@name']} in ${elementDefinition['@name']} must be equal or greater than ${minLength}`,
      validation,
    )
  }
}

function validatePattern(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['pattern'],
) {
  // In the original XSD file, patterns have only one backslash before the matcher d
  // We need to escape the backslashes so the pattern works in JS
  const originalPattern = restriction?.['@value'] || ''
  const patternWithEscapedSlashes = originalPattern.replace(/d/g, '\\d')
  const pattern = new RegExp(patternWithEscapedSlashes)

  if (value && !pattern.test(value)) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must match the pattern ${originalPattern}`,
      validation,
    )
  }
}

function validateEnumeration(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['enumeration'],
) {
  const enumValues = Array.isArray(restriction)
    ? restriction.map((enumValue) => enumValue['@value'])
    : [restriction?.['@value']]
  if (value?.length && !enumValues.includes(value)) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be one of the following: ${enumValues.join(', ')}`,
      validation,
    )
  }
}

function validateFractionDigits(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['fractionDigits'],
) {
  const fractionDigits = getIntegerValue(restriction)
  if (value && value.split('.')[1]?.length > fractionDigits) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be ${fractionDigits} digits long`,
      validation,
    )
  }
}

function validateMaxExclusive(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['maxExclusive'],
) {
  const maxExclusive = getIntegerValue(restriction)
  if (value && parseInt(value, 10) >= maxExclusive) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be less than ${maxExclusive}`,
      validation,
    )
  }
}

function validateMinExclusive(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['minExclusive'],
) {
  const minExclusive = getIntegerValue(restriction)
  if (value && parseInt(value, 10) <= minExclusive) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be greater than ${minExclusive}`,
      validation,
    )
  }
}

function validateMaxInclusive(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['maxInclusive'],
) {
  const maxInclusive = getIntegerValue(restriction)
  if (value && parseInt(value, 10) > maxInclusive) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be equal or less than ${maxInclusive}`,
      validation,
    )
  }
}

function validateMinInclusive(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string,
  validation: SchemaValidation,
  restriction: BMPRestriction['minInclusive'],
) {
  const minInclusive = getIntegerValue(restriction)
  if (value && parseInt(value, 10) < minInclusive) {
    addWarning(
      `${attributeDefinition['@name']} in ${elementDefinition['@name']} must be equal or greater than ${minInclusive}`,
      validation,
    )
  }
}

const restrictionValidators: { [key: string]: Function } = {
  length: validateLength,
  maxLength: validateMaxLength,
  minLength: validateMinLength,
  pattern: validatePattern,
  enumeration: validateEnumeration,
  fractionDigits: validateFractionDigits,
  maxExclusive: validateMaxExclusive,
  minExclusive: validateMinExclusive,
  maxInclusive: validateMaxInclusive,
  minInclusive: validateMinInclusive,
}

export function validateSimpleTypeRestrictions(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  value: string | null,
  validation: SchemaValidation,
) {
  const restrictions = attributeDefinition?.simpleType?.restriction

  if (!restrictions) return
  ;(Object.keys(restrictions) as (keyof BMPRestriction)[]).forEach((restrictionName) => {
    if (restrictionName === '@base') return // @base is just the type of the expected values i.e. string

    const restriction = restrictions[restrictionName]
    const validator = restrictionValidators[restrictionName]

    if (!validator) throw new Error(`Validator for restriction "${restrictionName}" not found`)

    validator(attributeDefinition, elementDefinition, value, validation, restriction)
  })
}
