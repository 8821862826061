import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="visibility"
export default class extends Controller {
  static targets = ['toggleable', 'showButton', 'hideButton']

  connect() {
    this.toggleableTargets.forEach((e: HTMLElement) => {
      e.classList.add('hidden')
    })

    this.hideButtonTarget.classList.add('hidden')
  }

  toggleTargets() {
    const allTogglleableItems = [
      this.toggleableTargets,
      this.showButtonTarget,
      this.hideButtonTarget,
    ].flat()

    allTogglleableItems.forEach((e: HTMLElement) => {
      if (e.classList.contains('hidden')) {
        e.classList.remove('hidden')
      } else {
        e.classList.add('hidden')
      }
    })
  }

  declare readonly toggleableTargets: HTMLElement[]
  declare readonly showButtonTarget: HTMLButtonElement
  declare readonly hideButtonTarget: HTMLButtonElement
}
