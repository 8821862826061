import { Controller } from '@hotwired/stimulus'

import { exportEncryptionKey } from '@samedi/crypto-js/crypto'
import { asymmetricEncrypt } from '@samedi/crypto-js/cryptor'

import { generateSessionKey, loadSessionKey } from '@samedi/crypto-js/subtle/symmetric'

import {
  loadDeviceKeyFromSessionOnce,
  getPatientKeyOnce,
  CSRFToken,
  decryptSessionData,
  encryptSessionData,
} from '../encryption/encryption'
import { loadEncryptedSessionKey } from '../encryption/keyHandling'
import { injectLinksToTextMessage, sanitizeMessageBody } from '../services/messages'

interface InstitutionPublicKey {
  id: string
  public_exponent: string
  modulus: string
}

interface MessagePayload {
  data: {
    message: {
      encrypted_body: string
      practice_id: string
      session_key: string
      session_key_patient_public_key_external_id: string
    }
    recipient_data: {
      encrypted_body: string
      sender_id: string
      recipient_id: string
    }
  }
}

export default class extends Controller {
  static values = {
    encryptedBody: String,
    sessionKey: String,
    sessionKeyPatientPublicKeyId: String,
    institutionId: String,
    patientUserId: String,
    institutionKeyPair: Object,
    isTextDecrypted: { type: Boolean, default: false },
  }

  static targets = ['messageText', 'messageContainer', 'textarea']

  initialize() {
    if (this.hasMessageContainerTarget) {
      this.messageContainerTarget.hidden = true
    }
  }

  async connect() {
    await loadDeviceKeyFromSessionOnce()

    let messageTextHtml: string | null = ''

    if (this.encryptedBodyValue) {
      try {
        const decryptedSessionKey = await loadEncryptedSessionKey(
          this.sessionKeyValue,
          this.sessionKeyPatientPublicKeyIdValue,
        )
        const decryptedText = await decryptSessionData(this.encryptedBodyValue, decryptedSessionKey)
        const sanitizedText = sanitizeMessageBody(decryptedText)
        messageTextHtml = injectLinksToTextMessage(sanitizedText)
      } catch {
        messageTextHtml = this.messageTextTarget.textContent
      } finally {
        this.isTextDecryptedValue = true
      }

      if (messageTextHtml) {
        this.messageTextTarget.innerHTML = messageTextHtml
      }
    }

    if (this.hasTextareaTarget) {
      this.textareaTarget.value = ''
      this.textareaTarget.focus()
    }
  }

  isTextDecryptedValueChanged() {
    if (this.hasMessageContainerTarget && this.isTextDecryptedValue) {
      this.messageContainerTarget.hidden = false
    }

    document
      .querySelector('#scroll-anchor')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  async create(event: FormDataEvent) {
    event.stopPropagation()
    event.preventDefault()

    if (this.hasTextareaTarget) {
      const plainMessage = this.textareaTarget.value

      if (plainMessage.trim().length !== 0) {
        const encryptedBodyForInstitution = await this.encryptForInstitution(plainMessage)
        const { patientKey, encryptedKeyData, encryptedBodyForPatient } =
          await this.encryptForPatient(plainMessage)

        const payload = {
          data: {
            message: {
              encrypted_body: encryptedBodyForPatient,
              practice_id: this.institutionIdValue,
              session_key: encryptedKeyData,
              session_key_patient_public_key_external_id: patientKey.id,
            },
            recipient_data: {
              encrypted_body: encryptedBodyForInstitution,
              sender_id: this.patientUserIdValue,
              recipient_id: this.institutionIdValue,
            },
          },
        }

        this.postMessage(payload).then(() => {
          // TODO: update page with turbo?
          window.location.reload()
        })
      }
    }
  }

  private async encryptForInstitution(plainMessage: string) {
    return await asymmetricEncrypt(
      plainMessage,
      this.institutionKeyPairValue.id,
      this.institutionKeyPairValue.public_exponent,
      this.institutionKeyPairValue.modulus,
    )
  }

  private async encryptForPatient(plainMessage: string) {
    const patientKey = await getPatientKeyOnce()
    const sessionKeyBytes = generateSessionKey()
    const siSessionKey = await loadSessionKey(sessionKeyBytes)
    const encryptedKeyData = await exportEncryptionKey(siSessionKey.base, patientKey.key)
    const encryptedBodyForPatient = await encryptSessionData(plainMessage, siSessionKey)

    return { patientKey, encryptedKeyData, encryptedBodyForPatient }
  }

  private async postMessage(payload: MessagePayload) {
    await fetch(`/messages?institution_id=${this.institutionIdValue}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRFToken(),
      },
      body: JSON.stringify(payload),
    })
  }

  declare encryptedBodyValue: string
  declare sessionKeyValue: string
  declare sessionKeyPatientPublicKeyIdValue: string
  declare institutionIdValue: string
  declare patientUserIdValue: string
  declare institutionKeyPairValue: InstitutionPublicKey
  declare isTextDecryptedValue: Boolean

  declare messageTextTarget: HTMLParagraphElement
  declare messageContainerTarget: HTMLDivElement
  declare textareaTarget: HTMLTextAreaElement
  declare hasTextareaTarget: Boolean
  declare hasMessageTextTarget: Boolean
  declare hasMessageContainerTarget: Boolean
}
