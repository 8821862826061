import { Controller } from '@hotwired/stimulus'

/*
 * Select fields don't have a placeholder like text fields.
 * This controller changes the class of a select field if a value is selected.
 */
export default class extends Controller {
  connect() {
    this.updatePlaceholderColor()
  }

  updatePlaceholderColor() {
    const dimmedTextClass = 'text-neutral-500'
    const normalTextClass = 'text-neutral-900'
    const select = this.element as HTMLSelectElement

    if (!select.value) {
      select.classList.add(dimmedTextClass)
      select.classList.remove(normalTextClass)
    } else {
      select.classList.remove(dimmedTextClass)
      select.classList.add(normalTextClass)
    }
  }
}
