import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="document-upload--progress-bar"
export default class extends Controller {
  static targets = ['percentage', 'bar', 'progress', 'fileName']
  connect() {}

  setFile(file: File) {
    this.fileNameTarget.innerHTML = file.name
    this.progressTarget.innerHTML = '0KB / ' + file.size + 'KB'
    this.percentageTarget.innerHTML = '0 %'
  }

  progress(percentage: number, size: number) {
    this.updateFileSizeTarget(percentage, size)
    this.percentageTarget.innerHTML = percentage + '%'
    this.barTarget.style.width = percentage + '%'
  }

  private bytesToHumanReadable(size: number, percentage: number = 100) {
    const units = ['bytes', 'KB', 'MB', 'GB']
    let unit = 0
    let sizeToTransform = size * (percentage / 100)

    while (sizeToTransform >= 1024 && ++unit) {
      sizeToTransform = sizeToTransform / 1024
    }

    return sizeToTransform.toFixed(sizeToTransform > 10 && unit > 0 ? 1 : 0) + ' ' + units[unit]
  }

  private updateFileSizeTarget(percentage: number, size: number) {
    const uploadedSize = this.bytesToHumanReadable(size, percentage)
    const totalSize = this.bytesToHumanReadable(size)
    this.progressTarget.innerHTML = uploadedSize + ' / ' + totalSize
  }

  declare percentageTarget: HTMLElement
  declare barTarget: HTMLElement
  declare progressTarget: HTMLElement
  declare fileNameTarget: HTMLElement
}
