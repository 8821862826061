import _ from 'lodash'

import { BMP, Section, MedicationItem, RecipeItem, FreetextItem } from '../../types/medicationPlan'

// https://www.kbv.de/media/sp/Medikationsplan_Anlage3_ab_01.04.2023.pdf
// Anhang 8 (XML-Schema, normativ) page 78
export default function createBMPStructure(xmlDoc: Document | null): BMP {
  if (!xmlDoc) throw new Error('No xmlDoc provided')

  const patient = xmlDoc.getElementsByTagName('P')[0]
  const administrationBlock = xmlDoc.getElementsByTagName('A')[0]
  const relevantPatientInformation = xmlDoc.getElementsByTagName('O')[0]

  if (!patient) throw new Error('No patient information found')

  const bmp: BMP = {
    patient: {
      firstName: patient.getAttribute('g') || '',
      lastName: patient.getAttribute('f') || '',
      insuranceId: patient.getAttribute('egk') || '',
      birthDate: patient.getAttribute('b') || '',
      gender: patient.getAttribute('s') || '',
      title: patient.getAttribute('t') || '',
      prefix: patient.getAttribute('v') || '',
      sufix: patient.getAttribute('z') || '',
    },
    relevantPatientInformation: {
      weight: relevantPatientInformation?.getAttribute('w') || '',
      height: relevantPatientInformation?.getAttribute('h') || '',
      creatinine: relevantPatientInformation?.getAttribute('c') || '',
      allergiesIntolerances: relevantPatientInformation?.getAttribute('ai') || '',
      breastfeeding: relevantPatientInformation?.getAttribute('b') || '',
      pregnancy: relevantPatientInformation?.getAttribute('p') || '',
      freetext: relevantPatientInformation?.getAttribute('x') || '',
    },
    administrationBlock: {
      printedBy: administrationBlock.getAttribute('n') || '',
      doctorNumber: administrationBlock.getAttribute('lanr') || '',
      pharmacyId: administrationBlock.getAttribute('idf') || '',
      institution: administrationBlock.getAttribute('kik') || '',
      street: administrationBlock.getAttribute('s') || '',
      zipCode: administrationBlock.getAttribute('z') || '',
      city: administrationBlock.getAttribute('c') || '',
      phone: administrationBlock.getAttribute('p') || '',
      email: administrationBlock.getAttribute('e') || '',
      printDateTime: administrationBlock.getAttribute('t') || '',
    },
    sections: [],
  }

  const sections = xmlDoc.getElementsByTagName('S')

  _.forEach(sections, (section) => {
    const title = section.getAttribute('t')
    const code = section.getAttribute('c')
    const sectionObj: Partial<Section> = {
      items: _.map(section.children, (item) => {
        switch (item.tagName) {
          case 'M':
            return extractMedicationDetails(item)
          case 'R':
            return extractRecipeDetails(item)
          case 'X':
            return extractFreeTextDetails(item)
          default:
            console.warn('section item not recognized', item.tagName)
        }
      }).filter(Boolean) as (MedicationItem | RecipeItem | FreetextItem)[],
    }

    if (code) {
      sectionObj.code = code
    } else if (title) {
      sectionObj.title = title
    }

    bmp.sections = _.concat(bmp.sections, sectionObj as Section)
  })

  return bmp
}

function extractMedicationDetails(medElement: Element): MedicationItem {
  const attrP = medElement.getAttribute('p')
  return {
    type: 'medication',
    activeIngredients: getActiveIngredientAttributes(medElement),
    boundAdditionalLine: medElement.getAttribute('x') || '',
    commercialName: medElement.getAttribute('a') || '',
    // Add back leading 0s. See Feldcode 4.0 in chapter 7.3.1
    pzn: attrP ? attrP.padStart(8, '0') : '',
    dosage: {
      // We have either a free text or the time-specific dosages
      morning: medElement.getAttribute('m') || '0',
      noon: medElement.getAttribute('d') || '0',
      evening: medElement.getAttribute('v') || '0',
      night: medElement.getAttribute('h') || '0',
      freeText: medElement.getAttribute('t') || '',
    },
    dosageUnitCode: medElement.getAttribute('du') || '',
    dosageUnitFreeText: medElement.getAttribute('dud') || '',
    instructions: medElement.getAttribute('i') || '',
    reason: medElement.getAttribute('r') || '',
    doseForm: medElement.getAttribute('f') || '',
    doseFormFreeText: medElement.getAttribute('fd') || '',
  }
}

function extractRecipeDetails(medElement: Element): RecipeItem {
  return {
    type: 'recipe',
    text: medElement.getAttribute('t') || '',
    boundAdditionalLine: medElement.getAttribute('x') || '',
  }
}

function extractFreeTextDetails(medElement: Element): FreetextItem {
  return {
    type: 'free_text',
    text: medElement.getAttribute('t') || '',
  }
}

function getActiveIngredientAttributes(medElement: Element) {
  const activeIngredients = medElement.getElementsByTagName('W')

  return _.map(activeIngredients, (activeIngredient) => {
    return {
      name: activeIngredient.getAttribute('w') || '',
      strength: activeIngredient.getAttribute('s') || '',
    }
  })
}
