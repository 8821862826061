import { toSVG } from 'bwip-js'

import { JSX } from 'services/jsx/jsx-runtime'

import { t } from '../../translations/i18n'
import {
  BMP,
  BMPAdministrationBlock,
  MedicationItem,
  RecipeItem,
  FreetextItem,
  BMPPatient,
} from '../../types/medicationPlan'

import { dosageUnitMappings } from './dosageUnitMappings'
import { doseFormMappings } from './doseFormMappings'
import { formatDateString, SectionTitleHtml, WithLineBreaks } from './helpers'

const MEDICATION_COLUMNS = 11

function EntryTable({
  item,
  withMedicationTableHead,
}: {
  item: MedicationItem | RecipeItem | FreetextItem
  withMedicationTableHead?: boolean
}): JSX.Element {
  if (!item) return null

  switch (item.type) {
    case 'medication':
    case 'recipe':
      return <MedicationRecipeEntryTable item={item} withHead={withMedicationTableHead} />
    case 'free_text':
      return <FreeTextEntry item={item} />
    default:
      console.error('Unknown item type', (item as any)?.type)
      return null
  }
}

function getDosageColumns(item: MedicationItem) {
  const collapseDosageColumns =
    !!item.dosage.freeText ||
    !(item.dosage.morning && item.dosage.noon && item.dosage.evening && item.dosage.night)

  return collapseDosageColumns
    ? [
        <td colSpan={4} className="colDosageFreeText">
          {item.dosage.freeText}
        </td>,
      ]
    : [
        <td className="colDosageMorning">{item.dosage.morning}</td>,
        <td className="colDosageNoon">{item.dosage.noon}</td>,
        <td className="colDosageEvening">{item.dosage.evening}</td>,
        <td className="colDosageNight">{item.dosage.night}</td>,
      ]
}

function MedicationTableHead(): JSX.Element {
  return (
    <thead>
      <tr>
        <th className="colActiveIngredient">{t('medication_plan.labels.active_ingredient')}</th>
        <th className="colCommercialName">{t('medication_plan.labels.commercial_name')}</th>
        <th className="colStrength">{t('medication_plan.labels.strength')}</th>
        <th className="colDoseForm">{t('medication_plan.labels.dose_form')}</th>
        <th className="colDosageMorning">{t('medication_plan.labels.dosage_morning')}</th>
        <th className="colDosageNoon">{t('medication_plan.labels.dosage_noon')}</th>
        <th className="colDosageEvening">{t('medication_plan.labels.dosage_evening')}</th>
        <th className="colDosageNight">{t('medication_plan.labels.dosage_night')}</th>
        <th className="colDosageUnit">{t('medication_plan.labels.dosage_unit')}</th>
        <th className="colInstructions">{t('medication_plan.labels.instructions')}</th>
        <th className="colReason">{t('medication_plan.labels.reason')}</th>
      </tr>
    </thead>
  )
}

function getActiveIngredientName(item: MedicationItem): JSX.Element[] {
  return item.activeIngredients.map((activeIngredient) => <div>{activeIngredient.name || ''}</div>)
}

function getActiveIngredientStrength(item: MedicationItem): JSX.Element[] {
  return item.activeIngredients.map((activeIngredient) => (
    <div>{activeIngredient.strength || ''}</div>
  ))
}

function MedicationRecipeEntryTable({
  item,
  withHead,
}: {
  item: MedicationItem | RecipeItem
  withHead?: boolean
}): JSX.Element {
  return (
    <table className={`entryTable ${item.boundAdditionalLine ? 'withBoundAdditionalLine' : ''}`}>
      {!!withHead && <MedicationTableHead />}
      <tbody>
        {item.type === 'medication' && (
          <tr>
            <td className="colActiveIngredient">{getActiveIngredientName(item)}</td>
            <td className="colCommercialName">{item.commercialName || ''}</td>
            <td className="colStrength">{getActiveIngredientStrength(item)}</td>
            <td className="colDoseForm">
              {item.doseFormFreeText || doseFormMappings(item.doseForm)}
            </td>
            {getDosageColumns(item)}
            <td className="colDosageUnit">
              {item.dosageUnitFreeText || dosageUnitMappings(item.dosageUnitCode)}
            </td>
            <td className="colInstructions">{item.instructions || ''}</td>
            <td className="colReason">{item.reason || ''}</td>
          </tr>
        )}
        {item.type === 'recipe' && (
          <tr>
            <td colSpan={MEDICATION_COLUMNS} className="recipeCell">
              <WithLineBreaks text={item.text} />
            </td>
          </tr>
        )}
        {item.boundAdditionalLine && (
          <tr>
            <td colSpan={MEDICATION_COLUMNS} className="boundAdditionalLineCell">
              <div className="ml-4 indentedCell">
                <WithLineBreaks text={item.boundAdditionalLine} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

function FreeTextEntry({ item }: { item: FreetextItem }): JSX.Element {
  return (
    <div className="freeTextEntry font-semibold">
      <WithLineBreaks text={item.text} />
    </div>
  )
}

function MedicationPlanTitle(): JSX.Element {
  return (
    <h2 className="text-[#00497f] text-base font-semibold leading-[27pt] border-b border-solid border-[#00497f] inline my-2">
      {t('medication_plan.title')}
    </h2>
  )
}

function PatientName({ patient }: { patient: BMPPatient }): JSX.Element {
  return (
    <div className="text-[color: #001d33] text-base font-semibold leading-6 my-2">
      {patient.firstName + ' ' + patient.lastName}
    </div>
  )
}

function HeaderLine({ value, label }: { value: string; label?: string }): JSX.Element {
  if (!value) return null

  return (
    <div className="text-[color: #001d33] text-xs font-normal leading-6">
      {typeof label !== 'undefined' && <span className="font-semibold">{label}: </span>}
      <span className="font-normal">{value}</span>
    </div>
  )
}

function Address({
  administrationBlock,
}: {
  administrationBlock: BMPAdministrationBlock
}): JSX.Element {
  const { street, zipCode, city } = administrationBlock
  return (
    <div className="flex gap-2 text-[color: #001d33] text-xs font-normal leading-6">
      <span className="font-normal">{street},</span>
      <span>{zipCode}</span>
      <span>{city}</span>
    </div>
  )
}

function Header({ bmp, dataMatrixCode }: { bmp: BMP; dataMatrixCode: string | null }): JSX.Element {
  const { patient, administrationBlock, relevantPatientInformation } = bmp
  const { printedBy, institution, phone, email, printDateTime } = administrationBlock
  const { birthDate, gender, insuranceId } = patient
  const { weight, height, creatinine, allergiesIntolerances, breastfeeding, pregnancy, freetext } =
    relevantPatientInformation

  const svgWrapper = document.createElement('div')

  if (dataMatrixCode) {
    svgWrapper.className = 'w-[140px] h-[140px]'
    svgWrapper.innerHTML = dataMatrixCode || ''
  }

  return (
    <div className="header flex justify-between shrink-0 rounded bg-[#f3f5f7] border border-solid border-[#acdcee] overflow-hidden px-4 py-2 my-4 w-full">
      <div className="py-2">
        <MedicationPlanTitle />
        <PatientName patient={patient} />
        <HeaderLine label={t('medication_plan.labels.printed_by')} value={printedBy} />
        <HeaderLine value={institution} />
        <HeaderLine label={t('medication_plan.labels.phone')} value={phone} />
        <HeaderLine label={t('medication_plan.labels.email')} value={email} />
        <Address administrationBlock={administrationBlock} />
      </div>
      <div className="py-2 text-right">
        <HeaderLine
          label={t('medication_plan.labels.patient_birthdate')}
          value={formatDateString(birthDate)}
        />
        <HeaderLine label={t('medication_plan.labels.gender')} value={gender} />
        <HeaderLine label={t('medication_plan.labels.insurance_number')} value={insuranceId} />
        <HeaderLine
          label={t('medication_plan.labels.weight')}
          value={weight && `${weight} ${t('medication_plan.labels.kgs')}`}
        />
        <HeaderLine
          label={t('medication_plan.labels.height')}
          value={height && `${height} ${t('medication_plan.labels.cms')}`}
        />
        <HeaderLine
          label={t('medication_plan.labels.creatinine')}
          value={creatinine && `${creatinine} ${t('medication_plan.labels.mg_dl')}`}
        />
        <HeaderLine
          label={t('medication_plan.labels.allergies_intolerances')}
          value={allergiesIntolerances}
        />
        {breastfeeding === '1' && <HeaderLine value={t('medication_plan.labels.breastfeeding')} />}
        {pregnancy === '1' && <HeaderLine value={t('medication_plan.labels.pregnancy')} />}
        <HeaderLine value={freetext} />
        <HeaderLine
          label={t('medication_plan.labels.printed_on')}
          value={formatDateString(printDateTime)}
        />
      </div>
      {dataMatrixCode && <div className="py-2">{svgWrapper}</div>}
    </div>
  )
}

function getDataMatrixCode(text: string): string | null {
  try {
    return toSVG({
      bcid: 'datamatrix',
      text,
    })
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 *
 * @param options { parsedBmp: BMP; rawBmpXml: string }
 * @returns a printable version of the medication plan
 */
export function PrintVersion({
  parsedBmp,
  rawBmpXml,
}: {
  parsedBmp: BMP
  rawBmpXml: string
}): JSX.Element {
  const compactedRawBmp = rawBmpXml.replace(/[\s+\t]+/g, ' ').replace(/\r\n/g, '')

  const firstMedicationSection = parsedBmp.sections.findIndex((section) =>
    section.items.some((item) => ['medication', 'recipe'].includes(item.type)),
  )

  return (
    <div className="hidden print:block">
      <Header bmp={parsedBmp} dataMatrixCode={getDataMatrixCode(compactedRawBmp)} />
      {parsedBmp.sections.map((section, sectionIndex) => {
        const firstMedicationItem = section.items.findIndex((item) =>
          ['medication', 'recipe'].includes(item.type),
        )

        return (
          <section>
            <SectionTitleHtml section={section} />
            {section.items.map((item, itemIndex) => {
              const showMedicationTableHead =
                sectionIndex === firstMedicationSection && itemIndex === firstMedicationItem
              return <EntryTable item={item} withMedicationTableHead={showMedicationTableHead} />
            })}
          </section>
        )
      })}
    </div>
  )
}
