import { Controller } from '@hotwired/stimulus'

import { loadDeviceKeyFromSessionOnce, redirectIfEncryptionLocked } from '../encryption/encryption'

// Connects to data-controller="prepare-encryption"
// Loads device keys from session to make sure that encryption/decryption works properly.
// If keys are not available, redirects the user to the unlocking form
export default class extends Controller {
  static targets = ['skeleton', 'content']
  static values = { isLocalAccount: Boolean }

  async connect() {
    await loadDeviceKeyFromSessionOnce()

    redirectIfEncryptionLocked()

    this.skeletonTarget.classList.add('hidden')
    this.contentTarget.classList.remove('hidden')

    window.dispatchEvent(new Event('resize'))
  }

  declare isLocalAccountValue: boolean
  declare skeletonTarget: HTMLElement
  declare contentTarget: HTMLElement
}
