{
  "devise.password_change.form.password_invalid": "Пароль не соответствует требованиям",
  "devise.password_change.form.password_match": "Пароли совпадают",
  "devise.password_change.form.password_mismatch": "Пароли не совпадают",
  "devise.password_change.form.password_valid": "Пароль соответствует требованиям",
  "document_upload.file_error_title": "Документ не может быть загружен",
  "document_upload.file_type_error": "Убедитесь, что документ имеет совместимый формат файла",
  "document_upload.upload_error": "В процессе загрузки произошла сетевая ошибка. Пожалуйста, попробуйте загрузить документ еще раз.",
  "document_upload.upload_error_title": "Произошла ошибка",
  "patient_forms.errors.invalid": "Одно или несколько полей формы отсутствуют или имеют неправильный формат. Пожалуйста, исправьте поля, отмеченные красным цветом. Вы также можете сохранить форму как черновик, но в этом случае вам придется отправить ее позже, чтобы ваша практика получила к ней доступ.",
  "patient_forms.errors.loading": "Произошла ошибка при загрузке существующих значений полей. Вы можете начать с нуля или обратиться в службу поддержки.",
  "patient_forms.errors.submitting": "Сохранение не удалось. Пожалуйста, проверьте подключение к Интернету и повторите попытку в более поздний момент времени."
}
