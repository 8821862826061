import { Context, Controller } from '@hotwired/stimulus'
import { BarcodeDetector, setZXingModuleOverrides } from 'barcode-detector'
import wasm from 'zxing-wasm/reader/zxing_reader.wasm'

setZXingModuleOverrides({
  locateFile: (path: string, prefix: string) => {
    if (path.endsWith('.wasm')) {
      // We cannot add `--public-path /assets` to esbuild because that breaks source maps in sprockets (and propshaft!)
      // https://github.com/rails/sprockets-rails/pull/501
      //
      // As a workaround, we manually add the public path here
      return `/assets/${wasm}`
    }

    return prefix + path
  },
})

// Connects to data-controller="emp-scanner"
/**
 * EmpScannerController allows to scan medication plans either from a camera or from a file
 *
 * File input can be an image with 2d code or xml file containing the raw XML data
 */
export default class extends Controller {
  static targets = [
    'video',
    'result',
    'fileInput',
    'scannerSelector',
    'resultPatientName',
    'resultMedicationCount',
    'scannerContainer',
  ]

  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  detector: BarcodeDetector
  stream?: MediaStream
  scanningActive: boolean = false

  constructor(context: Context) {
    super(context)

    this.canvas = document.createElement('canvas')
    this.canvas.width = 800
    this.canvas.height = 600

    this.ctx = this.canvas.getContext('2d', { willReadFrequently: true })!
    this.detector = new BarcodeDetector({ formats: ['data_matrix'] })
  }

  disconnect(): void {
    this.stopScanning()
  }

  showSection(section: 'scannerSelector' | 'scannerContainer' | 'result') {
    this.scannerSelectorTarget.classList.add('hidden')
    this.scannerContainerTarget.classList.add('hidden')
    this.resultTarget.classList.add('hidden')

    this[`${section}Target`].classList.remove('hidden')
  }

  async startScanning() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' },
      audio: false,
    })

    this.videoTarget.srcObject = stream
    this.stream = stream

    this.showSection('scannerContainer')

    const { width, height } = getVideoDimensions(stream)

    this.canvas.width = width
    this.canvas.height = height

    this.scanningActive = true

    this.dispatch('video-started', { detail: { stream } })
    this.scanAndKeepScanning()
  }

  private async scanAndKeepScanning() {
    if (!this.scanningActive || !this.stream) {
      return
    }

    const data = await this.scanVideoFrame()

    if (data) {
      this.setMedicationPlanData({ data })
      this.stopScanning()
    } else {
      setTimeout(() => this.scanAndKeepScanning(), 500)
    }
  }

  private async scanVideoFrame() {
    this.ctx.drawImage(this.videoTarget, 0, 0, this.canvas.width, this.canvas.height)

    const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height)
    const result = await this.detector.detect(imageData)

    if (result.length > 0) {
      return result[0].rawValue
    }
  }

  /**
   * Cancels scanning and shows the scanner selector
   */
  cancelScanning() {
    this.stopScanning()
    this.showSection('scannerSelector')
  }

  /**
   * Stops the camera stream and cancels scanning (without changing any UI state)
   */
  stopScanning() {
    this.scanningActive = false

    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop()
      }
      this.dispatch('video-stopped', { detail: { stream: this.stream } })
    }
  }

  selectFile() {
    this.fileInputTarget.click()
  }

  detectFile() {
    const file = this.fileInputTarget.files?.[0]
    if (!file) return

    if (file.type.startsWith('image')) {
      this.detectImage(file)
    } else if (file.type === 'text/xml' || file.type === 'application/xml') {
      this.detectFromXml(file)
    }
  }

  /**
   * Detects a medication plan from an XML file
   */
  async detectFromXml(file: File) {
    const arrayBuffer = await file.arrayBuffer()
    this.setMedicationPlanData({ data: new Uint8Array(arrayBuffer) })
  }

  /**
   * detects a medication plan from an image file by reading the 2d code
   */
  async detectImage(file: File) {
    const imageData = await extractImageDataFromFile(file)
    // If this step fails with "Barcode detection service unavailable.", then
    // the zxing-wasm and barcode-detector versions do not fit together.
    const result = await this.detector.detect(imageData)

    if (result.length > 0) {
      this.setMedicationPlanData({ data: result[0].rawValue })
    } else {
      alert('No medication plan found') // eslint-disable-line no-alert
    }
  }

  setMedicationPlanData({ data }: { data: string | Uint8Array }) {
    if (isValidMedicationPlan(data)) {
      this.showSection('result')
      this.dispatch('medicationPlanDetected', { detail: { data } })
    } else {
      alert('Invalid medication plan') // eslint-disable-line no-alert
    }
  }

  declare videoTarget: HTMLVideoElement
  declare resultTarget: HTMLCanvasElement
  declare fileInputTarget: HTMLInputElement
  declare scannerSelectorTarget: HTMLSelectElement
  declare resultPatientNameTarget: HTMLInputElement
  declare resultMedicationCountTarget: HTMLInputElement
  declare scannerContainerTarget: HTMLInputElement
}

function isValidMedicationPlan(data: string | Uint8Array, encoding = 'iso-8859-1'): boolean {
  let medicationPlanData: string

  if (typeof data === 'string') {
    medicationPlanData = data
  } else {
    medicationPlanData = new TextDecoder(encoding).decode(data)
  }

  const plan = parseMedicationPlan(medicationPlanData)

  return !!plan.name && !!plan.email
}

interface MedicationPlanContents {
  name: string
  email: string
  medications: string[]
}

function parseMedicationPlan(data: string): MedicationPlanContents {
  const parser = new DOMParser()
  const doc = parser.parseFromString(data, 'application/xml')

  const nameNode = doc.querySelector('MP>A')
  const name = nameNode?.getAttribute('n') ?? ''
  const email = nameNode?.getAttribute('e') ?? ''

  return { name, email, medications: [] }
}

function extractImageDataFromFile(file: File): Promise<ImageData> {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d', { willReadFrequently: true })!

  return new Promise((resolve) => {
    const img = new Image()

    img.onload = async () => {
      canvas.width = img.width
      canvas.height = img.height

      ctx.drawImage(img, 0, 0, img.width, img.height)

      const imageData = ctx.getImageData(0, 0, img.width, img.height)
      resolve(imageData)
    }

    img.src = URL.createObjectURL(file)
  })
}

function getVideoDimensions(stream: MediaStream): { width: number; height: number } {
  const settings = stream.getVideoTracks()[0].getSettings()

  return { width: settings.width || 640, height: settings.height || 480 }
}
