import { Controller } from '@hotwired/stimulus'

import { importEncryptionKey, importPublicKeyJWK } from '@samedi/crypto-js/crypto'

import {
  CSRFToken,
  DEVICE_KEY_STORAGE_ITEM_NAME,
  saveDeviceKeyToSessionStore,
  DeviceKey,
  loadDeviceKeyFromSession,
  redirectIfEncryptionLocked,
} from '../encryption/encryption'
import {
  importDeviceKeyPairPrivateKey,
  importPatientUserKeyPairPrivateKey,
  importPatientUserMasterKey,
} from '../encryption/keyHandling'

interface ProxyEntitlement {
  proxy_key_pair_public_key_id: string
  proxy_private_key_encrypted: string
  proxy_master_key_encrypted: string
  session_key_encrypted: string
  device_authentication_public_key_id: string
  device_authentication_public_key_jwk: string
  device_authentication_private_key_encrypted: string
}

const PROXY_OWN_DEVICE_KEY_STORAGE_ITEM_NAME = 'proxyOwnDeviceKey'

export default class extends Controller {
  static values = { proxyEntitlementId: String }

  private declare readonly proxyEntitlementIdValue: string
  private declare ownDeviceKey: DeviceKey

  async connect() {
    if (!this.isProxySession() && this.isProxySessionStarted()) {
      this.endProxySession()
      return
    }

    if (!this.isProxySession() || this.isProxySessionStarted()) {
      return
    }

    this.startProxySession()
  }

  private isProxySession() {
    return Boolean(this.proxyEntitlementIdValue.trim())
  }

  private isProxySessionStarted(): boolean {
    const proxyUserOwnDeviceKeyFromSession = window.sessionStorage.getItem(
      PROXY_OWN_DEVICE_KEY_STORAGE_ITEM_NAME,
    )

    return Boolean(proxyUserOwnDeviceKeyFromSession)
  }

  private async endProxySession() {
    const ownDeviceKey = window.sessionStorage.getItem(PROXY_OWN_DEVICE_KEY_STORAGE_ITEM_NAME)

    if (!ownDeviceKey) {
      throw new Error('Proxy session is already ended')
    }

    window.sessionStorage.setItem(DEVICE_KEY_STORAGE_ITEM_NAME, ownDeviceKey)
    window.sessionStorage.removeItem(PROXY_OWN_DEVICE_KEY_STORAGE_ITEM_NAME)
    await loadDeviceKeyFromSession()
  }

  private async startProxySession() {
    redirectIfEncryptionLocked()

    this.ownDeviceKey = await this.getOwnDeviceKeyFromSession()
    const proxyEntitlementDeviceKey = await this.loadDeviceKeyFromProxyEntitlement()

    await saveDeviceKeyToSessionStore(this.ownDeviceKey, PROXY_OWN_DEVICE_KEY_STORAGE_ITEM_NAME)
    await saveDeviceKeyToSessionStore(proxyEntitlementDeviceKey, DEVICE_KEY_STORAGE_ITEM_NAME)
    await loadDeviceKeyFromSession()
  }

  private async loadDeviceKeyFromProxyEntitlement(): Promise<DeviceKey> {
    const proxyEntitlement: ProxyEntitlement = await this.loadProxyEntitlement()
    const ownPrivateKey = await this.getOwnPrivateKey(proxyEntitlement)

    const sessionKey = await importEncryptionKey(
      ownPrivateKey,
      proxyEntitlement.session_key_encrypted,
      ['wrapKey', 'unwrapKey'],
    )

    return {
      id: proxyEntitlement.device_authentication_public_key_id,
      key: {
        publicKey: await importPublicKeyJWK(
          JSON.parse(proxyEntitlement.device_authentication_public_key_jwk),
          ['wrapKey'],
        ),
        privateKey: await importDeviceKeyPairPrivateKey(
          sessionKey,
          proxyEntitlement.device_authentication_private_key_encrypted,
        ),
      },
    }
  }

  private async getOwnDeviceKeyFromSession(): Promise<DeviceKey> {
    const sessionData = window.sessionStorage.getItem(DEVICE_KEY_STORAGE_ITEM_NAME)

    if (!sessionData) {
      throw new Error('Can not start proxy session: device key is not loaded')
    }

    const parsedSessionData = JSON.parse(atob(sessionData))

    return {
      id: parsedSessionData.id,
      key: {
        publicKey: await importPublicKeyJWK(parsedSessionData.key.public, ['wrapKey']),
        privateKey: await importPublicKeyJWK(parsedSessionData.key.private, ['unwrapKey']),
      },
    }
  }

  private async getOwnPrivateKey(proxyEntitlement: ProxyEntitlement): Promise<CryptoKey> {
    const masterKey = await importPatientUserMasterKey(
      this.ownDeviceKey.key.privateKey,
      proxyEntitlement.proxy_master_key_encrypted,
    )

    return importPatientUserKeyPairPrivateKey(
      masterKey,
      proxyEntitlement.proxy_private_key_encrypted,
    )
  }

  private async loadProxyEntitlement() {
    const request = await fetch(
      `/api/frontend/proxy_entitlements/${this.proxyEntitlementIdValue}?proxy_device_key_id=${this.ownDeviceKey.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': CSRFToken(),
        },
      },
    )

    const root = await request.json()

    return root.data
  }
}
