import bmpSchemaDefinitionTextFile from './bmp_V2.6'
import { findElementInSchemaByName, addError, addWarning } from './helpers'
import { validateSimpleTypeRestrictions } from './restrictionValidators'
import { BMPSchemaDefinition, BMPElement, BMPAttribute, SchemaValidation } from './types'
import { xsdToJsonParser } from './xsdToJsonParser'

function getBMPSchemaDefinition(): BMPSchemaDefinition {
  return xsdToJsonParser(bmpSchemaDefinitionTextFile)
}

/**
 * Builds a xml document from a string and validates it against the BMP schema.
 */
export function validateBMPXML(
  xml: string,
  jsonSchema = getBMPSchemaDefinition(),
): SchemaValidation {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(xml, 'application/xml')

  return validateXMLWithSchema(xmlDoc, jsonSchema)
}

/**
 * Builds the validation error object that will be passed down to every
 * validator function and starts the validation process with the root node.
 */
function validateXMLWithSchema(
  xmlDoc: Document,
  jsonSchema: BMPSchemaDefinition,
): SchemaValidation {
  const root = xmlDoc.documentElement
  const validation: SchemaValidation = {
    valid: true,
    errors: [],
    warnings: [],
  }

  validateNode(root, jsonSchema, validation)

  return validation
}

/**
 * A XML node in BMP contains either attributes or children elements (sequence).
 * This function validates the node attributes and children elements against the schema.
 */
function validateNode(node: Element, schema: BMPSchemaDefinition, validation: SchemaValidation) {
  if (node.tagName === 'parsererror') {
    addError(node.textContent || '', validation)
    return
  }

  const elementDefinition = findElementInSchemaByName(node.tagName, schema)

  // element does not exist in schema definition
  if (!elementDefinition) {
    addWarning(`${node.tagName} is not defined in the schema definition`, validation)
    return
  }

  validateAttributes(node, elementDefinition, validation)
  validateSequence(node, elementDefinition, schema, validation)

  return validation
}

/**
 * Validates the attributes of a node against the schema.
 */
function validateAttributes(
  node: Element,
  elementDefinition: BMPElement,
  validation: SchemaValidation,
) {
  const attributesDefinition = elementDefinition?.complexType?.attribute

  if (Array.isArray(attributesDefinition)) {
    attributesDefinition.forEach((attributeDefinition) => {
      validateAttributeDefinition(attributeDefinition, elementDefinition, node, validation)
    })
  } else {
    validateAttributeDefinition(attributesDefinition, elementDefinition, node, validation)
  }
}

/**
 * Gets the attribute name and value, checks if it's required and value is present.
 * Continues down the tree validating the attribute restrictions.
 */
function validateAttributeDefinition(
  attributeDefinition: BMPAttribute,
  elementDefinition: BMPElement,
  node: Element,
  validation: SchemaValidation,
) {
  const attributeName = attributeDefinition?.['@name']
  const isRequired = attributeDefinition?.['@use'] === 'required'

  if (!attributeName) {
    addError('Error trying to get attribute @name', validation)
  }

  const attributeValue = node.getAttribute(attributeName)

  if (!attributeValue?.length && isRequired) {
    addWarning(
      `${attributeDefinition['@name']} required in ${elementDefinition['@name']}`,
      validation,
    )
    return
  }

  validateSimpleTypeRestrictions(attributeDefinition, elementDefinition, attributeValue, validation)
}

/**
 * Loops through the node children elements (sequence) and validates them against the schema
 */
function validateSequence(
  node: Element,
  elementDefinition: BMPElement,
  schema: BMPSchemaDefinition,
  validation: SchemaValidation,
) {
  const sequenceDefinition = elementDefinition.complexType?.sequence

  if (!sequenceDefinition) return

  const nodeChildren: Element[] = Array.from(node.children)
  const sequenceElements = sequenceDefinition?.element

  if (nodeChildren.length > 0) {
    for (const child of nodeChildren) {
      validateNode(child, schema, validation)
    }
  }

  // no sequence defined for this element
  if (!sequenceElements) return

  if (Array.isArray(sequenceElements)) {
    sequenceElements.forEach((sequenceElement) => {
      validateSequenceElement(sequenceElement, node, schema, validation)
    })
  } else {
    validateSequenceElement(sequenceElements as BMPElement, node, schema, validation)
  }
}

/**
 * Validates children elements (sequence) against the schema.
 *
 */
function validateSequenceElement(
  sequenceElementDefinition: BMPElement,
  node: Element,
  schema: BMPSchemaDefinition,
  validation: SchemaValidation,
) {
  const tagNameInDefinition = sequenceElementDefinition['@name']
  const nodeChildren: Element[] = Array.from(node.children)
  const choiceDefinition = sequenceElementDefinition.complexType?.choice
  const elementsInSequence: Element[] = nodeChildren.filter(
    (child) => child.tagName === tagNameInDefinition,
  )
  const minOccurs =
    typeof sequenceElementDefinition['@minOccurs'] !== 'undefined'
      ? parseInt(sequenceElementDefinition['@minOccurs'], 10)
      : undefined
  const maxOccurs =
    typeof sequenceElementDefinition['@maxOccurs'] !== 'undefined'
      ? parseInt(sequenceElementDefinition['@maxOccurs'], 10)
      : undefined

  if (typeof minOccurs === 'number' && elementsInSequence.length < minOccurs) {
    addWarning(
      `${tagNameInDefinition} needs to appear at least ${minOccurs} times in ${node.tagName}`,
      validation,
    )
  }

  if (typeof maxOccurs === 'number' && elementsInSequence.length > maxOccurs) {
    addWarning(
      `${tagNameInDefinition} appears more than ${maxOccurs} times in ${node.tagName}`,
      validation,
    )
  }

  // no choice defined for this element
  if (!choiceDefinition) return

  if (Array.isArray(choiceDefinition.element)) {
    choiceDefinition.element.forEach((choiceElementDefinition) => {
      elementsInSequence.forEach((element) => {
        validateSequence(element, choiceElementDefinition, schema, validation)
      })
    })
  } else {
    elementsInSequence.forEach((element) => {
      validateSequence(element, choiceDefinition.element as BMPElement, schema, validation)
    })
  }
}
