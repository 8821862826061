import { deriveAuthKeyFromPassword, importPublicKeyJWK } from '@samedi/crypto-js/crypto'
import { symmetricEncryptBuffer, decrypt } from '@samedi/crypto-js/cryptor'
import {
  decrypt as symmetricDecryptUntagged,
  encrypt as symmetricEncryptUntagged,
  Key as SiSymmetricKey,
} from '@samedi/crypto-js/subtle/symmetric'
import { str2ab, bytesToUtf8String, utf8StringToBytes } from '@samedi/crypto-js/utils'
import { fromByteArray, toByteArray } from 'base64-js'

import {
  exportPatientUserMasterKey,
  exportPatientKeyPrivateKey,
  generateDeviceKeyPair,
  generatePatientKeyPair,
  generatePatientUserMasterKey,
  importPatientUserMasterKey,
  importPatientUserKeyPairPrivateKey,
  importPatientUserKeyPairPublicKey,
  importDeviceKeyPairPublicKey,
  exportDeviceKeyPublicKey,
  exportDeviceKeyPrivateKey,
  exportPatientUserKeyPairPublicKey,
  importDeviceKeyPairPrivateKey,
} from './keyHandling'

export interface DeviceKey {
  key: CryptoKeyPair
  id: string
}

interface PatientUserMasterKey {
  key: CryptoKey
  id: string
}

export interface PatientPublicKey {
  id: string
  key: CryptoKey
  plain?: any
}

export interface EncryptionKey {
  id: string
  key: CryptoKey
}

export interface AESEncryptionKey {
  plain: Uint8Array
  webCrypto: undefined | SiSymmetricKey
}

export interface Key {
  id: string
  key: AESEncryptionKey
}

export const DEVICE_KEY_STORAGE_ITEM_NAME = 'deviceKey'
const UNLOCK_ENCRYPTION_PATH = '/encryption/unlock'

export interface AuthKey {
  key: CryptoKey
  pbkdfSalt: string
  pbkdfIterations: number
}

export function CSRFToken() {
  return document.querySelector<HTMLMetaElement>("meta[name='csrf-token']")?.content || ''
}

let deviceKey: DeviceKey | null

export function isDeviceKeyLoaded() {
  return Boolean(deviceKey)
}

export function getCurrentDeviceKey(): DeviceKey {
  if (!deviceKey) {
    throw new Error('Device is not trusted')
  }

  return deviceKey
}

function isUnlockEncryptionPage(): boolean {
  return window.location.pathname === UNLOCK_ENCRYPTION_PATH
}

export function redirectIfEncryptionLocked() {
  if (isDeviceKeyLoaded() || isUnlockEncryptionPage()) {
    return
  }

  window.location.href = UNLOCK_ENCRYPTION_PATH
}

let patientKeyPromise: Promise<PatientPublicKey>
export async function getPatientKeyOnce(): Promise<PatientPublicKey> {
  if (!patientKeyPromise) {
    const deviceKey = getCurrentDeviceKey()
    patientKeyPromise = getPatientKey(deviceKey.id).then(async (key) => {
      if (!key) {
        const masterKey = await getMasterKey()
        return await generateAndUploadPatientKey(masterKey)
      }

      return key
    })
  }

  return patientKeyPromise
}

async function getPatientKey(deviceKeyId: string): Promise<PatientPublicKey | null> {
  const params = new URLSearchParams({ device_key_id: deviceKeyId })
  const request = await fetch(`/patient_keys/active?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
  })

  if (!request.ok) {
    if (request.status === 404) {
      return null
    } else {
      throw new Error('Error fetching Patient Key')
    }
  }

  const response = await request.json()
  const key = await importPatientUserKeyPairPublicKey(response.public_key_jwk)

  return { id: response.id, key, plain: response.public_key_jwk }
}

export async function loadDeviceKeyPairForPassword(password: string) {
  deviceKey = await loadDeviceKeyPair(password)

  if (deviceKey) {
    saveDeviceKeyToSessionStore(deviceKey)
  }

  return Boolean(deviceKey)
}

export async function saveDeviceKeyToSessionStore(
  deviceKeyToSave: DeviceKey,
  storageItemName: string = DEVICE_KEY_STORAGE_ITEM_NAME,
) {
  const jwkPublicKey = await crypto.subtle.exportKey('jwk', deviceKeyToSave.key.publicKey)
  const jwkPrivateKey = await crypto.subtle.exportKey('jwk', deviceKeyToSave.key.privateKey)

  const deviceKeyData = {
    id: deviceKeyToSave.id,
    key: {
      public: jwkPublicKey,
      private: jwkPrivateKey,
    },
  }

  const sessionData = btoa(JSON.stringify(deviceKeyData))

  window.sessionStorage.setItem(storageItemName, sessionData)
}

let loadDeviceKeyPromise: Promise<void>

export async function loadDeviceKeyFromSessionOnce() {
  if (!loadDeviceKeyPromise) {
    loadDeviceKeyPromise = loadDeviceKeyFromSession()
  }

  return loadDeviceKeyPromise
}

export async function loadDeviceKeyFromSession() {
  const sessionDataFromStore = window.sessionStorage.getItem(DEVICE_KEY_STORAGE_ITEM_NAME)

  if (sessionDataFromStore) {
    const sessionData = JSON.parse(atob(sessionDataFromStore))

    deviceKey = await setupDeviceKeyFromSessionData(sessionData)
  }
}

async function setupDeviceKeyFromSessionData(sessionData: any): Promise<DeviceKey | null> {
  const request = await fetch(`/device_keys/${sessionData.id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
  })

  if (request.ok) {
    return {
      id: sessionData.id,
      key: {
        publicKey: await importPublicKeyJWK(sessionData.key.public, ['wrapKey']),
        privateKey: await importPublicKeyJWK(sessionData.key.private, ['unwrapKey']),
      },
    }
  }

  return null
}

export async function existRecoveryToken(): Promise<Boolean> {
  const recoveryTokenKeys = await fetchRecoveryTokenKeyPairs()

  return recoveryTokenKeys.length > 0
}

async function findDeviceKeyPair(password: string, passwordKeys: any[]) {
  for (const key of passwordKeys) {
    try {
      const baseKey = await deriveAuthKeyFromPassword(
        password,
        key.pbkdf_salt,
        key.pbkdf_iterations,
      )
      const privateKey = await importDeviceKeyPairPrivateKey(baseKey, key.private_key)
      const publicKey = await importDeviceKeyPairPublicKey(key.public_key)

      if (privateKey) {
        return { key: { privateKey, publicKey }, id: key.public_key_id }
      }
    } catch (e) {}
  }

  return null
}

async function loadDeviceKeyPair(password: string): Promise<DeviceKey | null> {
  const passwordKeys = await fetchPasswordKeyPairs()
  return await findDeviceKeyPair(password, passwordKeys)
}

export async function loadDeviceKeyPairForRecoveryKey(recoveryKey: string) {
  const recoveryTokens = await fetchRecoveryTokenKeyPairs()
  deviceKey = await findDeviceKeyPair(recoveryKey, recoveryTokens)

  if (deviceKey) {
    saveDeviceKeyToSessionStore(deviceKey)
  }

  return Boolean(deviceKey)
}

async function fetchPasswordKeyPairs(): Promise<any[]> {
  const passwordPrivateKeyRequest = await fetch('/password_authentications', {
    headers: {
      Accept: 'application/json',
    },
  })

  return passwordPrivateKeyRequest.json()
}

function resetPasswordToken(): string | null {
  return window.sessionStorage.getItem('reset_password_token')
}

async function fetchRecoveryTokenKeyPairs(): Promise<any[]> {
  const endpoint = resetPasswordToken()
    ? `/recovery_tokens?reset_password_token=${resetPasswordToken()}`
    : '/recovery_tokens'
  const recoveryTokensRequest = await fetch(endpoint, {
    headers: {
      Accept: 'application/json',
    },
  })
  return recoveryTokensRequest.json()
}

// ######################################################
// # This feature is needed for C5 compliance for PSS-07
// ######################################################
export async function generateAuthKeyFromPassword(password: string): Promise<AuthKey> {
  const salt = fromByteArray(crypto.getRandomValues(new Uint8Array(16)))
  // https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html#pbkdf2
  // recommends at least a work factor of 210000 for PBKDF2-HMAC-SHA512
  const pbkdfIterations = 600000

  const baseKey = await deriveAuthKeyFromPassword(password, salt, pbkdfIterations)
  return { key: baseKey, pbkdfSalt: salt, pbkdfIterations }
}

export async function generateAndUploadDeviceKeyPairForPassword(password: string) {
  const deviceKeyPair = await generateAndUploadDeviceKeyPair(password, false)
  await generateAndLoadMasterAndPatientKey(deviceKeyPair)

  await saveDeviceKeyToSessionStore(deviceKeyPair)
  return deviceKeyPair
}

export async function generateAndUploadDeviceKeyPairForRecoveryToken(recoveryToken: string) {
  const deviceKeyPair = await generateAndUploadDeviceKeyPair(recoveryToken, true)

  return deviceKeyPair
}

// ######################################################
// # This feature is needed for C5 compliance for PSS-07
// ######################################################
export async function hashPassword(email: string, password: string) {
  const pbkdfIterations = await fetchPasswordIterations(email)

  const saltBuffer = new TextEncoder().encode(email.toLowerCase())
  const salt = await crypto.subtle.digest('SHA-512', saltBuffer)
  const hashedPassword = getPbkdf2Derivation(password, pbkdfIterations, salt)

  return hashedPassword
}

/**
 * It returns the PBKDF2 derived key from the supplied password.
 *
 * @param {string} password The plain password to be hashed.
 * @param {Number} iterations The PBKDF Iterations to be used for the work factor.
 * @param {ArrayBuffer} salt The salt used to hash the password.
 * @returns {string} PBKDF2 derived key as base64 string.
 */
async function getPbkdf2Derivation(password: string, iterations: Number, salt: ArrayBuffer) {
  const passwordBuffer = new TextEncoder().encode(password)
  const passwordKey = await crypto.subtle.importKey('raw', passwordBuffer, 'PBKDF2', false, [
    'deriveBits',
  ])

  const params = { name: 'PBKDF2', hash: 'SHA-512', salt, iterations }
  const keyBuffer = await crypto.subtle.deriveBits(params, passwordKey, 256)

  return fromByteArray(new Uint8Array(keyBuffer))
}

async function generateAndUploadDeviceKeyPair(password: string, isRecoveryToken: boolean) {
  const baseKey = await generateAuthKeyFromPassword(password)
  const deviceKeyPair = await generateDeviceKeyPair()

  const masterKeys = isDeviceKeyLoaded() ? await getMasterKeys() : []
  const masterKeyData = await Promise.all(
    masterKeys.map(async (key) => {
      return {
        id: key.id,
        data_encrypted: await exportPatientUserMasterKey(deviceKeyPair.publicKey, key.key),
      }
    }),
  )

  const exportedPrivateKey = await exportDeviceKeyPrivateKey(baseKey.key, deviceKeyPair.privateKey)
  const exportedPublicKey = await exportDeviceKeyPublicKey(deviceKeyPair.publicKey)
  const requestBody = {
    public_key: exportedPublicKey,
    private_key: exportedPrivateKey,
    pbkdf_salt: baseKey.pbkdfSalt,
    pbkdf_iterations: baseKey.pbkdfIterations,
    master_key_data: masterKeyData,
  }

  const endpoint: string = isRecoveryToken ? '/recovery_tokens' : '/password_authentications'

  const request = await fetch(endpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
    body: JSON.stringify(requestBody),
  })

  if (!request.ok) {
    throw new Error('Failed to upload device key pair')
  }

  const response = await request.json()

  return { key: deviceKeyPair, id: response.public_key_id }
}

async function generateAndLoadMasterAndPatientKey(
  deviceKey: DeviceKey,
): Promise<PatientUserMasterKey[]> {
  const patientUserMasterKey = await generatePatientUserMasterKey()

  const key = deviceKey
  const encryptedPatientUserMasterKey = await exportPatientUserMasterKey(
    key.key.publicKey,
    patientUserMasterKey,
  )

  const patientUserPatientUserMasterKeyRequest = await uploadPatientUserMasterKey(
    encryptedPatientUserMasterKey,
    key.id,
  )
  const patientUserPatientUserMasterKeyResponse =
    await patientUserPatientUserMasterKeyRequest.json()

  const patientUserPatientUserMasterKeyResult = {
    id: patientUserPatientUserMasterKeyResponse.id,
    key: patientUserMasterKey,
  }

  await generateAndUploadPatientKey(patientUserPatientUserMasterKeyResult)

  return [patientUserPatientUserMasterKeyResult]
}

async function uploadPatientUserMasterKey(
  encryptedPatientUserMasterKey: string,
  deviceKeyId: string,
) {
  const requestBody = {
    patient_user_master_key_encrypted: encryptedPatientUserMasterKey,
    encryption_key_id: deviceKeyId,
  }

  const patientUserMasterKeyRequest = await fetch('/patient_user_master_keys', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
    body: JSON.stringify(requestBody),
  })

  return patientUserMasterKeyRequest
}

export async function reencryptAndUploadPatientUserMasterKeyDataWithPassword(password: string) {
  await generateAndUploadDeviceKeyPair(password, false)
}

const patientPrivateKeys: Record<string, Promise<CryptoKey>> = {}

export async function fetchPatientPrivateKeyOnce(keyId: string): Promise<CryptoKey> {
  if (!patientPrivateKeys[keyId]) {
    patientPrivateKeys[keyId] = fetchPatientPrivateKey(keyId)
  }
  return patientPrivateKeys[keyId]
}

export async function fetchPatientPrivateKey(keyId: string): Promise<CryptoKey> {
  const deviceKey = getCurrentDeviceKey()

  const params = new URLSearchParams({ device_key_id: deviceKey.id })
  const request = await fetch(`/patient_keys/${keyId}?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
  })

  const response = await request.json()
  const masterKey = await importPatientUserMasterKey(
    deviceKey.key.privateKey,
    response.patient_user_master_key_encrypted,
  )

  const privatePatientKey = await importPatientUserKeyPairPrivateKey(
    masterKey,
    response.private_key_encrypted,
  )
  return privatePatientKey
}

async function fetchMasterKeyData(
  deviceKeyId: string,
): Promise<{ id: string; data_encrypted: string }[]> {
  const token = resetPasswordToken()
  const params = new URLSearchParams({ device_key_id: deviceKeyId })
  if (token) {
    params.append('reset_password_token', token)
  }
  const endpoint = `/patient_user_master_keys?${params}`

  const request = await fetch(endpoint, {
    method: 'GET',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })

  const response = await request.json()
  return response.data
}

export async function getMasterKeys(): Promise<PatientUserMasterKey[]> {
  const deviceKey = getCurrentDeviceKey()
  const masterKeyData = await fetchMasterKeyData(deviceKey.id)

  return Promise.all(
    masterKeyData.map(async (keyData) => {
      const cryptoKey = await importPatientUserMasterKey(
        deviceKey.key.privateKey,
        keyData.data_encrypted,
      )
      return { id: keyData.id, key: cryptoKey }
    }),
  )
}

export async function getMasterKey(): Promise<PatientUserMasterKey> {
  const deviceKey = getCurrentDeviceKey()
  const keyData = await fetchMasterKeyData(deviceKey.id)
  const cryptoKey = await importPatientUserMasterKey(
    deviceKey.key.privateKey,
    keyData[0].data_encrypted,
  )

  return { id: keyData[0].id, key: cryptoKey }
}

async function generateAndUploadPatientKey(
  patientUserPatientUserMasterKey: PatientUserMasterKey,
): Promise<PatientPublicKey> {
  const patientKeyPair = await generatePatientKeyPair()
  const exportedPrivateKey = await exportPatientKeyPrivateKey(
    patientUserPatientUserMasterKey.key,
    patientKeyPair.privateKey,
  )
  const exportedPublicKey = await exportPatientUserKeyPairPublicKey(patientKeyPair.publicKey)

  const requestBody = {
    patient_user_master_key_id: patientUserPatientUserMasterKey.id,
    public_key: exportedPublicKey,
    private_key: exportedPrivateKey,
  }

  const request = await fetch('/patient_keys', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
    body: JSON.stringify(requestBody),
  })

  const response = await request.json()

  const patientKey = { key: patientKeyPair.publicKey, id: response.public_key_id }
  return patientKey
}

export function encryptText(text: string, key: Key) {
  return symmetricEncryptBuffer(str2ab(text), key)
}

export function decryptText(encryptedText: string, key: AESEncryptionKey) {
  return decrypt(encryptedText, key)
}

/**
 * Decrypt session data. In contrast to other encrypted strings, session data is
 * assumed to not be tagged with the algorithm or key id.
 *
 * @param {string} clearText The data string in utf8. E.g. xml or JSON
 * @param {Key} siSessionKey The session key as resolved from `loadSession`
 * @returns {string} base64 encoded bytes. Untagged.
 */
export async function encryptSessionData(
  clearText: string,
  siSessionKey: SiSymmetricKey,
): Promise<string> {
  return fromByteArray(await symmetricEncryptUntagged(utf8StringToBytes(clearText), siSessionKey))
}

/**
 * Decrypt session data. In contrast to other encrypted strings, session data is
 * assumed to not be tagged with the algorithm or key id.
 *
 * @param {string} encryptedText The data string, untagged.
 * @param {Key} siSessionKey The session key as resolved from `loadSession`
 * @returns {string} Interpretation of the decrypted Buffer as a utf8 string. Might contain XML or JSON, or plain data.
 */
export async function decryptSessionData(
  encryptedText: string,
  siSessionKey: SiSymmetricKey,
): Promise<string> {
  return bytesToUtf8String(await symmetricDecryptUntagged(toByteArray(encryptedText), siSessionKey))
}

async function fetchPasswordIterations(email: string): Promise<Number> {
  const params = new URLSearchParams({ email })
  const request = await fetch(`/patient_users/iterations?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  const response = await request.json()

  return response.data.iterations
}
