import {
  exportEncryptionKey,
  exportPrivateKey,
  exportPublicKey,
  generateEncryptionKey,
  generateKeyPair,
  importEncryptionKey,
  importPrivateKey,
  importPublicKeyJWK,
} from '@samedi/crypto-js/crypto'
import { Key as SiSymmetricKey, loadSessionKey } from '@samedi/crypto-js/subtle/symmetric'

import { fetchPatientPrivateKeyOnce } from './encryption'

/**
 * Exports a encryption key into a string.
 */
export async function exportEncryptionKeyInRawFormat(
  encryptionKey: CryptoKey,
): Promise<ArrayBuffer> {
  return await crypto.subtle.exportKey('raw', encryptionKey)
}

/** Generates a new, random master key that can be used to wrap/unwrap patient key pairs.
 * This is a 256 bit AES-CGM key.
 */
export function generatePatientUserMasterKey(): Promise<CryptoKey> {
  return generateEncryptionKey(['wrapKey', 'unwrapKey'])
}

/**
 * Exports a patient user master key into a string.
 * The key is an AES key, jwk encoded, and encrypted with a device private key using RSA-OAEP.
 */
export async function exportPatientUserMasterKey(
  devicePublicKey: CryptoKey,
  patientUserPatientUserMasterKey: CryptoKey,
): Promise<string> {
  return exportEncryptionKey(patientUserPatientUserMasterKey, devicePublicKey)
}

/**
 * Imports an encrypted AES key that was exported with exportPatientUserMasterKey.
 */
export async function importPatientUserMasterKey(
  devicePrivateKey: CryptoKey,
  masterKeyDataEncrypted: string,
): Promise<CryptoKey> {
  return importEncryptionKey(devicePrivateKey, masterKeyDataEncrypted, ['wrapKey', 'unwrapKey'])
}

/** Generates a new random device key pair that is used to encrypt/decrypt master keys */
export function generateDeviceKeyPair(): Promise<CryptoKeyPair> {
  return generateKeyPair(['wrapKey', 'unwrapKey'])
}

/** Generates a new random device key pair that is used to encrypt/decrypt data */
export function generatePatientKeyPair(): Promise<CryptoKeyPair> {
  return generateKeyPair(['wrapKey', 'unwrapKey', 'encrypt', 'decrypt'])
}

/**
 * Exports a patient key pair private key as a string. It is encrypted with a patient user master key.
 * The private key is a RSA-OEAP key pair. It is jwk encoded and encrypted using AES-GCM with a random 12 byte IV prepended.
 */
export async function exportPatientKeyPrivateKey(
  patientUserMasterKey: CryptoKey,
  patientUserKeyPairPublicKey: CryptoKey,
): Promise<string> {
  return exportPrivateKey(patientUserKeyPairPublicKey, patientUserMasterKey)
}

export async function exportPatientUserKeyPairPublicKey(key: CryptoKey): Promise<JsonWebKey> {
  return exportPublicKey(key)
}

/**
 * Imports a patient user private key that was exported with exportPatientKeyPrivateKey.
 */
export function importPatientUserKeyPairPrivateKey(
  patientUserMasterKey: CryptoKey,
  privateKeyData: string,
): Promise<CryptoKey> {
  return importPrivateKey(privateKeyData, patientUserMasterKey, ['unwrapKey', 'decrypt'])
}

export function importDeviceKeyPairPrivateKey(
  baseKey: CryptoKey,
  privateKeyData: string,
): Promise<CryptoKey> {
  return importPrivateKey(privateKeyData, baseKey, ['unwrapKey'])
}

export async function importPatientUserKeyPairPublicKey(jwk: JsonWebKey) {
  return importPublicKeyJWK(jwk, ['wrapKey', 'encrypt'])
}

export async function importDeviceKeyPairPublicKey(jwk: JsonWebKey) {
  return importPublicKeyJWK(jwk, ['wrapKey'])
}

export async function exportDeviceKeyPublicKey(key: CryptoKey): Promise<JsonWebKey> {
  return exportPublicKey(key)
}

export async function exportDeviceKeyPrivateKey(
  baseKey: CryptoKey,
  deviceKey: CryptoKey,
): Promise<string> {
  return exportPrivateKey(deviceKey, baseKey)
}

export async function loadEncryptedSessionKey(
  encryptedSessionKey: string,
  keyId: string,
): Promise<SiSymmetricKey> {
  const patientPrivateKey = await fetchPatientPrivateKeyOnce(keyId)

  const importedSessionKey = await importEncryptionKey(
    patientPrivateKey,
    encryptedSessionKey,
    ['encrypt', 'decrypt'],
    undefined, // should be AES-GCM, but we have existing keys using AES-CBC
  )

  const rawSessionKey = new Uint8Array(await crypto.subtle.exportKey('raw', importedSessionKey))
  return await loadSessionKey(rawSessionKey)
}
