import { Controller } from '@hotwired/stimulus'

export type Severity = 'error' | 'warning'

// Connects to data-controller="document-upload--error-message"
export default class extends Controller {
  static targets = ['title', 'message']

  static classes = ['error', 'warning']

  set(title: string, message: string, severity: Severity = 'error') {
    this.titleTarget.classList.remove(...this.errorClasses, ...this.warningClasses)

    switch (severity) {
      case 'error':
        this.titleTarget.classList.add(...this.errorClasses)
        break
      case 'warning':
        this.titleTarget.classList.add(...this.warningClasses)
        break
    }

    this.titleTarget.innerText = title
    this.messageTarget.innerText = message

    this.show()
  }

  clear() {
    this.element.classList.add('hidden')
  }

  show() {
    this.element.classList.remove('hidden')
  }

  declare titleTarget: HTMLElement
  declare messageTarget: HTMLElement

  declare errorClasses: string[]
  declare warningClasses: string[]
}
