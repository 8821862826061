import type { JSX } from './jsx-runtime'

function appendChildByType(node: HTMLElement, children: JSX.Children) {
  if (typeof children === 'boolean') return
  if (typeof children === 'string') node.appendChild(document.createTextNode(children))
  if (children instanceof HTMLElement) node.appendChild(children)
  if (Array.isArray(children)) {
    children.forEach((child) => {
      appendChildByType(node, child)
    })
  }
}

function setAttribute<TNode extends HTMLElement, TKey extends keyof TNode>(
  node: TNode,
  attr: TKey | string,
  value: TNode[TKey],
) {
  if (attr in node) {
    node[attr as TKey] = value
  } else {
    node.setAttribute(String(attr), `${value}`)
  }
}

export function createElement(
  type: keyof JSX.IntrinsicElements | Function,
  props: Object,
): JSX.Element {
  if (typeof type !== 'string') return type(props)

  const node = document.createElement(type)

  Object.entries(props).forEach(([attr, value]) => {
    if (attr === 'children') {
      appendChildByType(node, value)
    } else {
      setAttribute(node, attr, value)
    }
  })

  return node
}
