// Temporarily ignore absent types until we patch streamsaver properly, INT-164
// @ts-ignore
import streamSaver from './StreamSaver'

interface DownloadFileArguments {
  filename: string
  contentType?: string
  size?: number
}

export default async function downloadFileFromStream(
  stream: ReadableStream<Uint8Array>,
  opts: DownloadFileArguments,
): Promise<void> {
  // const userAgent = navigator && (navigator.userAgent || '')
  // const isIE = !!/Trident/.test(userAgent)
  // const isEdge = !!/Edge/.test(userAgent)

  // if (isIE || isEdge) {
  //   const blobContent: ArrayBuffer[] = []
  //   const storeToBlobStream = new WritableStream<Uint8Array>(
  //     {
  //       write(decryptedU8Chunk) {
  //         blobContent.push(decryptedU8Chunk.buffer)
  //       }
  //     },
  //     new CountQueuingStrategy({ highWaterMark: 1 })
  //   )
  //   await stream.pipeTo(storeToBlobStream)
  //   const blob = new Blob(blobContent, { type: opts.contentType })
  //   FileSaver.saveAs(blob, opts.filename)
  //   return
  // }

  streamSaver.mitm = '/streamsaver_mitm.html?version=2.2.0'
  const fileStream = streamSaver.createWriteStream(opts.filename, {
    size: opts.size,
  })
  await stream.pipeTo(fileStream)
}
