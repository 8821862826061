import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="asap-notifications"
export default class extends Controller {
  static targets = ['form', 'input']

  update() {
    if (this.formTarget && this.inputTarget) {
      // This is a hack to avoid double form submission, which seems to happen quite often in Safari
      // Drawback: User might feel a bit delayed by state changes
      this.inputTarget.disabled = true
      this.formTarget.requestSubmit()
    }
  }

  declare formTarget: HTMLFormElement
  declare inputTarget: HTMLInputElement
}
