import { importEncryptionKey } from '@samedi/crypto-js/crypto'

import { AESEncryptionKey, decryptText, fetchPatientPrivateKeyOnce } from '../encryption/encryption'
import { exportEncryptionKeyInRawFormat } from '../encryption/keyHandling'

import readChunkedEncryptedFileFromURLs from './readFileEncryptedFileFromURLs'

export interface EncryptedSessionKey {
  patientKeyPairId: string
  encryptedData: string
}

export interface FileData {
  id: string
  fileName: string
  metaData: { size: number; fileDescription?: string; kdlCode?: string }
  encryptionKey: AESEncryptionKey
}

export default async function getFileStream(
  fileId: string,
  encryptionKey: AESEncryptionKey,
): Promise<ReadableStream<Uint8Array>> {
  const urls = await urlsForFileId(fileId)

  return readChunkedEncryptedFileFromURLs(urls, encryptionKey)
}

export async function getDecryptedKey(key: EncryptedSessionKey) {
  const patientPrivateKey = await fetchPatientPrivateKeyOnce(key.patientKeyPairId)

  const importedKey = await importEncryptionKey(patientPrivateKey, key.encryptedData, [
    'encrypt',
    'decrypt',
  ])
  const rawKey = await exportEncryptionKeyInRawFormat(importedKey)

  const decryptedAESEncryptionKey = { plain: new Uint8Array(rawKey), webCrypto: undefined }
  return decryptedAESEncryptionKey
}

export async function decryptFileMetadata(
  encryptionKey: EncryptedSessionKey,
  encryptedMetadata: string,
  encryptedName: string,
): Promise<Omit<FileData, 'id'>> {
  const key = await getDecryptedKey(encryptionKey)

  const decryptedMetaData = encryptedMetadata
    ? JSON.parse(await decryptText(encryptedMetadata, key))
    : { size: 0 }
  const decryptedFileName = await decryptText(encryptedName, key)
  const metaData = {
    size: decryptedMetaData.size,
    fileDescription: decryptedMetaData.file_description,
    kdlCode: decryptedMetaData.kdl_code,
  }

  return {
    fileName: decryptedFileName,
    metaData,
    encryptionKey: key,
  }
}

export async function getRawFileData(fileId: string): Promise<any> {
  const request = await fetch(`/files/${fileId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  return await request.json()
}

async function urlsForFileId(fileId: string) {
  const urlParams = `file_id=${fileId}`
  const request = await fetch(`/file_chunks?${urlParams}`)
  const response = await request.json()
  return response.chunk_ids.map((id: string) => `/file_chunks/${id}?${urlParams}`)
}
