import { Controller } from '@hotwired/stimulus'

import { t } from 'translations/i18n'

import { getPatientKeyOnce } from '../encryption/encryption'
import uploadFile from '../services/uploadFiles'

import { MEDICATION_PLAN_KDL_CODE } from '../shared_constants'

import ErrorMessageController from './document_upload/error_message_controller'
import ProgressBarController from './document_upload/progress_bar_controller'
import EmpScannerController from './emp_scanner_controller'

const addHiddenClass = (element: HTMLElement) => {
  element.classList.add('hidden')
}

const removeHiddenClass = (element: HTMLElement) => {
  element.classList.remove('hidden')
}

export default class extends Controller {
  static targets = [
    'fileSelection',
    'fileUpload',
    'fileName',
    'progressBar',
    'fileSizeProgress',
    'errorMessage',
    'progressPercentage',
    'progressBar',
    'documentDescriptionInput',
    'fileInput',
    'fileInputName',
    'confirmButton',
    'empScanner',
  ]

  static values = {
    documentRequestId: String,
    institutionKey: String,
    appointmentId: String,
    maxFileSizeBytes: Number,
    allowedFileTypes: Array<String>,
  }

  kdlCode: string = ''

  async connect() {}

  open() {
    this.errorMessage.clear()
  }

  onEmpScannerMedicationPlanDetected(
    event: CustomEvent<{ data: string | Uint8Array }>,
    encoding: string = 'iso-8859-1',
  ) {
    const { data } = event.detail
    const file = new File(
      [typeof data === 'string' ? data : new TextDecoder(encoding).decode(data)],
      'medication_plan.xml',
      { type: 'text/xml' },
    )
    const dataTransfer = new DataTransfer()

    dataTransfer.items.add(file)

    this.fileInputTarget.files = dataTransfer.files
    this.kdlCode = MEDICATION_PLAN_KDL_CODE

    this.enableConfirmButton()
  }

  async upload() {
    const fileInput = this.fileInputTarget
    const documentDescription = this.hasDocumentDescriptionInputTarget
      ? this.documentDescriptionInputTarget.value
      : null

    const files = fileInput.files

    let institutionKey = null
    if (this.institutionKeyValue) {
      const parsedInstitutionKey = JSON.parse(this.institutionKeyValue)

      institutionKey = {
        id: parsedInstitutionKey.id,
        institutionId: parsedInstitutionKey.institution_id,
        modulus: parsedInstitutionKey.modulus,
        publicExponent: parsedInstitutionKey.public_exponent,
      }
    }

    if (!files) {
      throw Error('No file input')
    }

    const file = files[0]

    this.initializeProgressElements(file)

    const patientKey = await getPatientKeyOnce()

    let documentRequestId = null

    if (this.documentRequestIdValue !== 'document_upload') {
      documentRequestId = this.documentRequestIdValue
    }

    const additionalMetaData: Record<string, string> = {}

    if (this.kdlCode) {
      additionalMetaData['kdl_code'] = this.kdlCode
    }

    await uploadFile(
      file,
      documentDescription,
      additionalMetaData,
      patientKey,
      documentRequestId,
      institutionKey,
      this.updateProgressElements.bind(this),
    )
      .then(() => {
        if (this.hasAppointmentIdValue) {
          window.open(`/appointments/${this.appointmentIdValue}`, '_self')
        } else {
          window.open('/files', '_self')
        }
      })
      .catch((e) => {
        this.errorMessage.set(
          t('document_upload.upload_error_title'),
          t('document_upload.upload_error'),
        )
        throw e
      })
  }

  onFileChanged() {
    if (!this.fileInputTarget.files) return

    const file = this.fileInputTarget.files[0]

    if (file.size > this.maxFileSizeBytesValue) {
      this.fileInputNameTarget.innerHTML = ''
      this.errorMessage.set(
        t('document_upload.file_error_title'),
        t('document_upload.file_size_error'),
      )

      this.disableConfirmButton()
    } else {
      if (!this.isAllowedFileType(file.type)) {
        this.fileInputNameTarget.innerHTML = ''
        this.errorMessage.set(
          t('document_upload.file_error_title'),
          t('document_upload.file_type_error'),
        )

        this.disableConfirmButton()
      } else {
        this.fileInputNameTarget.innerHTML = file.name
        this.fileInputNameTarget.classList.remove('text-info-500')
        this.fileInputNameTarget.classList.add('text-info-900')

        this.errorMessage.clear()

        this.enableConfirmButton()
      }
    }
  }

  disableConfirmButton() {
    this.confirmButtonTarget.disabled = true
  }

  enableConfirmButton() {
    this.confirmButtonTarget.disabled = false
  }

  isAllowedFileType(type: string) {
    return this.allowedFileTypesValue.some((allowedType) => type.startsWith(allowedType))
  }

  initializeProgressElements(file: File) {
    this.progressBar.setFile(file)
    addHiddenClass(this.fileSelectionTarget)
    removeHiddenClass(this.progressBarTarget)
  }

  updateProgressElements(percentage: number, size: number) {
    this.progressBar.progress(percentage, size)
  }

  get errorMessage() {
    return this.application.getControllerForElementAndIdentifier(
      this.errorMessageTarget,
      'document-upload--error-message',
    ) as ErrorMessageController
  }

  get progressBar() {
    return this.application.getControllerForElementAndIdentifier(
      this.progressBarTarget,
      'document-upload--progress-bar',
    ) as ProgressBarController
  }

  get empScanner() {
    return this.application.getControllerForElementAndIdentifier(
      this.empScannerTarget,
      'emp-scanner',
    ) as EmpScannerController
  }

  declare fileSelectionTarget: HTMLElement
  declare fileInputNameTarget: HTMLElement
  declare fileInputTarget: HTMLInputElement
  declare progressBarTarget: HTMLElement
  declare confirmButtonTarget: HTMLLinkElement
  declare documentDescriptionInputTarget: HTMLInputElement
  declare hasDocumentDescriptionInputTarget: boolean
  declare errorMessageTarget: HTMLElement

  declare documentRequestIdValue: string
  declare institutionKeyValue: string
  declare appointmentIdValue: string
  declare hasAppointmentIdValue: boolean
  declare maxFileSizeBytesValue: number
  declare allowedFileTypesValue: string[]

  declare empScannerTarget: HTMLElement
}
