import { Controller } from '@hotwired/stimulus'

interface ActionParams {
  params: {
    id?: string
  }
}

const modalElement = (id?: string) => {
  if (id) {
    const elementId = 'modal-container-' + id
    return document.getElementById(elementId)
  } else {
    return document.getElementById('modal-container')
  }
}

const outsideModalClickListener = (event: Event, id?: string) => {
  const modal = modalElement(id)

  if (!modal) return

  if (event.target === modal) {
    addHiddenClassToElement(modal)
    removeOutsideModalClickListener(id)
  }
}

const addOutsideModalClickListener = (id?: string) => {
  removeOutsideModalClickListener(id)

  document.addEventListener('click', (event) => outsideModalClickListener(event, id))
}

const removeOutsideModalClickListener = (id?: string) => {
  document.removeEventListener('click', (event) => outsideModalClickListener(event, id))
}

const addHiddenClassToElement = (modal: HTMLElement) => {
  if (!modal) return

  if (!modal.classList.contains('hidden')) {
    modal.classList.add('hidden')
  }
}

const removeHiddenClassFromElement = (modal: HTMLElement) => {
  if (!modal) return

  if (modal.classList.contains('hidden')) {
    modal.classList.remove('hidden')
  }
}

export default class extends Controller {
  disconnect() {
    removeOutsideModalClickListener()
  }

  open({ params: { id } }: ActionParams) {
    const modal = modalElement(id)

    if (!modal) return

    addOutsideModalClickListener(id)
    removeHiddenClassFromElement(modal)
  }

  close({ params: { id } }: ActionParams) {
    const modal = modalElement(id)

    if (!modal) return

    removeOutsideModalClickListener(id)
    addHiddenClassToElement(modal)
  }
}
