import { Controller } from '@hotwired/stimulus'

import {
  getMasterKeys,
  generateAuthKeyFromPassword,
  hashPassword,
  loadDeviceKeyPairForPassword,
} from '../encryption/encryption'
import {
  exportDeviceKeyPrivateKey,
  exportDeviceKeyPublicKey,
  exportPatientUserMasterKey,
  generateDeviceKeyPair,
} from '../encryption/keyHandling'

/**
 * EncryptionController allow the creation and set of the encryption keys
 */
export default class ChangePasswordController extends Controller<HTMLFormElement> {
  async setParams(event: CustomEvent) {
    event.preventDefault()
    if (await loadDeviceKeyPairForPassword(this.element.patient_user_current_password.value)) {
      for (const [key, value] of Object.entries(await this.requestParams())) {
        event.detail.fetchOptions.body.set(key, value)
      }
    }

    // ######################################################
    // # This feature is needed for C5 compliance for PSS-07
    // ######################################################
    await this.hashPasswords(event)
    event.detail.resume()
  }

  async hashPasswords(event: CustomEvent) {
    const hashedCurrentPassword = await hashPassword(
      this.element.patient_user_email.value,
      this.element.patient_user_current_password.value,
    )
    const hashedPassword = await hashPassword(
      this.element.patient_user_email.value,
      this.element.patient_user_password.value,
    )
    const hashedPasswordConfirmation = await hashPassword(
      this.element.patient_user_email.value,
      this.element.patient_user_password_confirmation.value,
    )

    event.detail.fetchOptions.body.set('patient_user[current_password]', hashedCurrentPassword)
    event.detail.fetchOptions.body.set('patient_user[password]', hashedPassword)
    event.detail.fetchOptions.body.set(
      'patient_user[password_confirmation]',
      hashedPasswordConfirmation,
    )
  }

  async connect() {}

  async encryptedMasterKeyData(publicKey: CryptoKey) {
    const masterKeys = await getMasterKeys()
    const masterKeyData = await Promise.all(
      masterKeys.map(async (key) => {
        return {
          id: key.id,
          data_encrypted: await exportPatientUserMasterKey(publicKey, key.key),
        }
      }),
    )
    return masterKeyData
  }

  async requestParams() {
    const baseKey = await generateAuthKeyFromPassword(this.element.patient_user_password.value)
    const deviceKeyPair = await generateDeviceKeyPair()
    const exportedPrivateKey = await exportDeviceKeyPrivateKey(
      baseKey.key,
      deviceKeyPair.privateKey,
    )
    const exportedPublicKey = await exportDeviceKeyPublicKey(deviceKeyPair.publicKey)

    return {
      public_key: JSON.stringify(exportedPublicKey),
      private_key: exportedPrivateKey,
      pbkdf_salt: baseKey.pbkdfSalt,
      pbkdf_iterations: baseKey.pbkdfIterations,
      master_key_data: JSON.stringify(await this.encryptedMasterKeyData(deviceKeyPair.publicKey)),
    }
  }
}
