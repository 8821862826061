import { exportEncryptionKey, generateEncryptionKey } from '@samedi/crypto-js/crypto'
import { asymmetricEncrypt } from '@samedi/crypto-js/cryptor'

import { CSRFToken, EncryptionKey, PatientPublicKey } from './encryption'
import { exportEncryptionKeyInRawFormat } from './keyHandling'

/**
 * Generates a new encryption key with usage 'sharing' on platform
 *
 * @param {Object} patientPublicKey - public key of the patient
 *
 * @returns <Promise<number>> resolves to the id of created sharing key
 */
export async function generateSharedEncryptionKey(
  publicKey: PatientPublicKey,
): Promise<EncryptionKey> {
  const key = await generateEncryptionKey(['encrypt', 'decrypt'])
  const keyData = await exportEncryptionKey(key, publicKey.key)
  const savedKey = await uploadEncryptionKey(keyData, publicKey.id)

  return { id: savedKey.id, key }
}

export async function encryptInstitutionKeyData(encryptionKey: any, institutionKey: any) {
  const keyDataForPractice = await exportEncryptionKeyInRawFormat(encryptionKey)

  return await asymmetricEncrypt(
    keyDataForPractice,
    institutionKey.id,
    institutionKey.publicExponent,
    institutionKey.modulus,
    null,
    false,
  )
}

export async function shareEncryptionKeyWithInstitution(encryptionKey: any, institutionKey: any) {
  const institutionKeyData = await encryptInstitutionKeyData(encryptionKey.key, institutionKey)

  await uploadEncryptionKeyData(institutionKeyData, encryptionKey.id, institutionKey.institutionId)
}

async function uploadEncryptionKey(keyData: string, publicKeyId: string) {
  const requestBody = { data: keyData, patient_public_key_id: publicKeyId }

  const response = await makePostRequest('/encryption_keys', requestBody)

  return response
}

async function uploadEncryptionKeyData(
  keyData: string,
  encryptionKeyId: string,
  institutionId: string,
) {
  const requestBody = {
    data: keyData,
    institution_id: institutionId,
  }

  const response = await makePostRequest(
    `/encryption_keys/${encryptionKeyId}/share_with_institution`,
    requestBody,
  )

  return response
}

async function makePostRequest(url: string, requestBody: object) {
  const request = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': CSRFToken(),
    },
    body: JSON.stringify(requestBody),
  })

  return await request.json()
}
