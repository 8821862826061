import { decryptStreamToBinary } from '@samedi/crypto-js/cryptor'

export default function readChunkedEncryptedFileFromURLs(
  urls: string[],
  key: object,
): ReadableStream<Uint8Array> {
  const stream = new ReadableStream<string>(
    {
      async pull(controller) {
        const url = urls.shift()

        if (!url) {
          controller.close()
          return
        }

        const request = await fetch(url)
        const response = await request.json()

        controller.enqueue(response.encrypted_data)
      },
    },
    new CountQueuingStrategy({ highWaterMark: 1 }),
  )

  const getKey = async (cipher: string) => key

  return decryptStreamToBinary(stream, getKey)
}
