import { intl, t } from '../../translations/i18n'

export const doseFormMappings = (doseForm: string): string => {
  const translationKey = 'medication_plan.dose_forms.' + doseForm

  if (doseForm && intl.messages[translationKey]) {
    return t(translationKey)
  } else {
    return doseForm
  }
}
