{
  "devise.password_change.form.password_invalid": "Parola gereksinimleri karşılamıyor",
  "devise.password_change.form.password_match": "Şifreler eşleşir",
  "devise.password_change.form.password_mismatch": "Parolalar eşleşmiyor",
  "devise.password_change.form.password_valid": "Parola gereksinimleri karşılar",
  "document_upload.file_error_title": "Belge yüklenemiyor",
  "document_upload.file_type_error": "Lütfen belgenin uyumlu bir dosya formatına sahip olduğunu kontrol edin",
  "document_upload.upload_error": "Yükleme işlemi sırasında bir ağ hatası oluştu. Lütfen belgeyi tekrar yüklemeyi deneyin.",
  "document_upload.upload_error_title": "Bir hata oluştu",
  "patient_forms.errors.invalid": "Bir veya daha fazla form alanı eksik veya geçersiz biçime sahip. Lütfen kırmızı işaretli alanları düzeltin. Formu taslak olarak da kaydedebilirsiniz, ancak muayenehanenizin forma erişebilmesi için daha sonra göndermeniz gerekecektir.",
  "patient_forms.errors.loading": "Mevcut alan değerleri yüklenirken bir hata oluştu. Sıfırdan başlayabilir veya destek ekibiyle iletişime geçebilirsiniz.",
  "patient_forms.errors.submitting": "Kaydetme işlemi başarısız oldu. Lütfen internet bağlantınızı kontrol edin ve daha sonra tekrar deneyin."
}
