import { Controller } from '@hotwired/stimulus'

export default class ProxyManagementController extends Controller<HTMLFormElement> {
  static targets = ['tokenInput', 'tokenButton']

  async connect() {
    this.tokenButtonTarget.disabled = true
  }

  typeToken() {
    const minTokenLength = 6

    if (this.tokenInputTarget.value.length >= minTokenLength) {
      this.tokenButtonTarget.disabled = false
    } else {
      this.tokenButtonTarget.disabled = true
    }
  }

  declare tokenInputTarget: HTMLInputElement
  declare tokenButtonTarget: HTMLButtonElement
}
